/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

/**
 * vložení kategorie
 */
export const SEND_NEWSLETTER = gql`
    mutation SendOneNewsletter(
        $newsletterID:ID,
        $photo:Upload,
        $uploadedPhoto:String,
        $testEmail:String,
        $isTest:Boolean,
        $campaignID:ID,
        $selectedLang:String,
        $langs:[NewsletterLangsInput],
        $isPlanned:Int,
        $plannedDate: String
        $generateTranslations:GenerateNewsletterTranslationsInput
    ){
        sendOneNewsletter(
            newsletterID:$newsletterID,
            photo:$photo,
            uploadedPhoto:$uploadedPhoto,
            testEmail:$testEmail,
            isTest:$isTest,
            campaignID:$campaignID,
            selectedLang:$selectedLang,
            langs:$langs,
            isPlanned:$isPlanned,
            plannedDate:$plannedDate,
            generateTranslations: $generateTranslations
        )
        {
            newsletterID
            isTest
        }
    }
`;

export const GET_NEWSLETTER_DATA = gql`
    query GetNewsletterData($lang:String){
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        allPlannedNewsletters(lang:$lang){
            newsletterID
            subject
            plannedDateString
            plannedDate
            preheader
            photo
            text
            testEmail
            campaignID
            langs{
                lang
                subject
                text
                preheader
            }
        }
        emailCountInNewsletter{
            socialNetworkID
            lang
            count
            socialNetwork{
                name
            }
        }
        allCampaigns{
            campaignID
            name
        }
    }
`;

export const DELETE_NEWSLETTERS = gql`
    mutation DeleteNewsletters($ids:[ID]){
        deleteNewsletters(ids:$ids)
    }
`;
export const GENERATE_NEWSLETTER_LANG = gql`
    mutation GenerateNewsletterLang(
        $lang: String,
        $newsletterID: ID,
        $isEdit: Boolean,
        $onlyForParams:[ID]
    ){
        GenerateNewsletterLang(
            lang:$lang,
            newsletterID:$newsletterID,
            isEdit:$isEdit,
            onlyForParams:$onlyForParams
        )
    }
`;



