let graphqlServerUrl              = "https://server.astral.university/graphql";
let serverUrl                     = "https://server.astral.university";

if (process && process.env && process.env.NODE_ENV === "development") {
    graphqlServerUrl              = "http://localhost:8924/graphql";
    serverUrl                     = "http://localhost:8924";
}

export const GRAPHQL_SERVER_URL              = graphqlServerUrl;
export const SERVER_URL                      = serverUrl;

export const WEB_URL                         = "https://www.astral.university";
export const TINYMCE_IMAGE_URL               = "Public/Images/TinyMCE";
export const TINYMCE_FILE_URL                = "Public/Files/TinyMCE";
export const MARKETING_IMAGE_URL             = "Public/Images/Marketing";
export const ARTICLE_IMAGE_URL               = "Public/Images/Article";
export const CONTENT_MANAGER_IMAGE_URL       = "Public/Images/ContentManager";
export const CONTENT_MANAGER_VIDEO_URL       = "Public/Videos/ContentManager";
export const NEWSLETTER_IMAGE_PATH           = "Public/Images/Newsletter";
export const CATEGORY_IMAGE_PATH             = "Public/Images/Category";
export const MEMBERSHIP_IMAGE_PATH           = "Public/Images/Membership";
export const SERVICE_IMAGE_PATH              = "Public/Images/Services";
export const AUDIO_URL                       = "Public/Audio";
