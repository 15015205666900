/*
    Výběr veškerých kategorií
*/
import React,{ Component } from 'react';
import {Query,Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import NoItems from '../../../GlobalComponents/NoItems';
import {GET_FORMS,DELETE_FORMS} from '../Queries/index.js';
import AddEditForm from './AddEditForm';
import Helper from '../../../Library/helper';
import edit from '../../../Public/Images/edit.svg';
import Notification from '../../../Library/notification';
import FormLib from '../Library/form';
import { withApollo } from 'react-apollo';

const INITIAL_STATE = {
    showDeleteCategoryNotifi : false,
    showDoNotDelete:false,
    showAddCategory:false,
    selectedFormID:0,
    formID:0,
    deleteCat:null,
    selectedItems:[],
    showAddCategory : false,
    showDeleteNotifi:false,
    listVariables:{
        lang:"cz"
    }

}

class AllForms extends Component{


    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
    }


    delCategory = (action) => {
        if(action){
            let formIDs = this.state.selectedItems.join(",");
            this.state.deleteForms({
                variables:{
                    formIDs
                }
            });
            
            this.setState(INITIAL_STATE);
        }else{
            this.setState({
                showDeleteNotifi:false
            });
        }
    }

    showDoNotDel = (action) => {
        this.setState({
            showDoNotDelete:action
        });
    }

    openCloseModal = (type,formID) => {
        this.setState({
            showAddCategory:type,
            selectedFormID:formID
        })
    }
    
    /**
     * smazání kategorie
     * @param {*} deleteForms funkce, která posílá data na server
     * @param {*} formID ID kategorie
     */
    
    checkItemToDelete = (e,deleteForms,formID) => {

        var checked = e.target.checked;
        var arr = [...this.state.selectedItems];

        if(!checked){
            for(let i in this.state.selectedItems){
                if(this.state.selectedItems[i] == formID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [formID,...arr];
        }
        
        this.setState({
            deleteForms,
            selectedItems:arr
        });
  
    }
    
    openCloseAddCategoryModal = (type) => {
        this.setState({
            showAddCategory:type,
        })
    }
    
    showDelNotifi = () => {

        if(this.state.selectedItems.length > 0){
            this.setState({
                showDeleteNotifi:true
            });
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste žádnou položku.',false,true,this.props.client);
        }
    }
    onDragEnd = (result,updateCategoryPriority) => {

        const IDs = result.draggableId.split("-");

        updateCategoryPriority({
            variables:{
                formID: IDs[0],
                fromIndex:result.source.index,
                toIndex:  result.destination.index
            }
        });

    }

    render(){
        
        const {selectedItems,listVariables} = this.state;

        return(
            <div id="settings" className="whole-container" >
            	
                <div className="row" >
                    <div className="col-sm one">
                        <div className="card main">
                            
                            <div className="card-header d-flex align-items-center">
                                Seznam formulářů
                                <button className="btn btn-primary btn-thiner ml-auto" onClick={() => this.openCloseModal(true)}>Přidat</button>
                                <button className="btn btn-danger btn-thiner ml-2" onClick={() => this.showDelNotifi()}>Smazat označené</button>
                            </div>
                            <div className="card-body">
                                <Query query={GET_FORMS} errorPolicy="all" variables={listVariables}>
                                    {
                                        ({data,loading,error,refetch }) => {
                                            if(error){
                                                const helper = new Helper(); 
                                                return (<Error text={helper.getApolloErrorText(error)} />);
                                            }
                                            if(loading) return (<Loading />);
                                            
                                            return(
                                                <div>
                                                    <div className="data-list">
                                                        
                                                            <div className="data-list-item header">
                                                                <div className="data-list-item-content">
                                                                    <div>Název formuláře</div>
                                                                    <div className="text-right">Možnosti</div>
                                                                </div>
                                                            </div>

                                                            <Mutation
                                                                mutation = {DELETE_FORMS}
                                                                update = {async (cache, response) => {
                                                                    
                                                                    let form = new FormLib(this);
                                                                    form.updateAfterDelete(cache, response);

                                                                    let notify = new Notification();
                                                                    notify.setNotification(cache,'Úspěšně smazáno',true,false);
                                                
                                                                }}
                                                                onError = {(error) => {
                                                                    const helper = new Helper(); 
                                                                    let notify = new Notification();
                                                                    notify.setNotification(null,helper.getApolloErrorText(error),false,true,this.props.client);
                                                                }}
                                                            >
                                                                {
                                                                    (deleteForms,response) => {
                                                                
                                                                        return(
                                                                            
                                                                            <div>
                                                                                {data.allForms && data.allForms.map((item,index) => {
                                                                                    
                                                                                    if(item.formID){
                                                                                        
                                                                                        var checked = false;
                                                                                        for(let i in selectedItems){
                                                                                            if(selectedItems[i] == item.formID)checked = true;
                                                                                        }

                                                                                        return (
                                                                                            
                                                                                            <div key={index} className="data-list-item">
                                                                                                <div className="data-list-item-content categories">
                                                                                                    <div className="cross" style={{paddingLeft:10}}>
                                                                                                        {item.name}
                                                                                                    </div>
                                                                                                    <div className="text-right">
                                                                                                        <img onClick={() => this.openCloseModal(true,item.formID)} className="edit-icon" src={edit} /> 
                                                                                                        <input className="delete-checked" type="checkbox" name="formsToDelete[]" checked={checked} onChange = {(e) => this.checkItemToDelete(e,deleteForms,item.formID)} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }else{
                                                                                        return "";
                                                                                    }
                                                                                })}
                                                                            </div>
                                                                        )
                                                                    }
                                                                }
                                                            </Mutation>
                                                        
                                                    </div>
                                                    {data.allForms && data.allForms.length == 0 &&
                                                        <NoItems text={"Momentálně se zde nenachází žádné položky."} />
                                                    }
                                                    {this.state.showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat vybrané položky ?'} callback={this.delCategory} /> }
                                                    {this.state.showAddCategory &&
                                                        <AddEditForm 
                                                            listVariables={listVariables} 
                                                            openCloseModal={this.openCloseModal} 
                                                            selectedFormID={this.state.selectedFormID} 
                                                            refetch = {refetch}
                                                        />
                                                    }
                                                </div>
                                            );
                                        }
                                    }
                                </Query>
                            </div>
                        </div>
                    </div>
                </div> 			
            </div>
        
        );

    }

}


export default withApollo(AllForms);