/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací zákazníků
 */

import {GET_CUSTOMER,GET_CUSTOMERS,GET_CUSTOMER_BY_EMAIL} from '../Queries/customer.js';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';

class Customer {

    constructor(parent){
        this.parent = parent;
    }

    /**
     * při inicializaci přidání zákazníka nebo jeho úpravy vytáhne potřebná data k zákazníkovi
     */

    async getData(){

        var customerID = 0;
        if(this.parent.props.selectedCustomerID)customerID = this.parent.props.selectedCustomerID;
        
        var data = await this.parent.props.client.query({ 
            query: GET_CUSTOMER,
            errorPolicy:"all",
            variables:{customerID:customerID,lang:"cz"}, 
            fetchPolicy: 'network-only'
        });

        if(data.errors){
            this.parent.setState({apolloError:data.errors[0].message});
        }

        if(data.data){

            var allCountries = [];
            var allMembershipDurations = [];
            var allDomains = [];
            var countryID = 0;
            var deliveryCountryID = 0;
            
            if(data.data.allDomains && data.data.allDomains.length > 0){
                allDomains = data.data.allDomains;
            }

            if(data.data.allAdminMembershipDurations && data.data.allAdminMembershipDurations.length > 0){
                allMembershipDurations = data.data.allAdminMembershipDurations;
            }

            if(data.data.allCountries && data.data.allCountries.length > 0){
                allCountries =  data.data.allCountries;
                for(let val of allCountries){
                    if(val.defaultSelected == 1){
                        countryID = val.countryID;
                        deliveryCountryID = val.countryID;
                    }
                }
            }
            
            if(data.data.oneCustomer){
                
                this.parent.setState({
                    apolloError:"",selectedCustomerID:customerID, allCountries,allMembershipDurations,allDomains,
                    formData:{...this.parent.state.formData,
                        customerID:          data.data.oneCustomer.customerID,
                        name:                data.data.oneCustomer.name,
                        surname:             data.data.oneCustomer.surname,
                        email:               data.data.oneCustomer.email,
                        tel:                 data.data.oneCustomer.tel,
                        street:              data.data.oneCustomer.street,
                        city:                data.data.oneCustomer.city,
                        zip:                 data.data.oneCustomer.zip,
                        countryID:           data.data.oneCustomer.countryID,
                        domainID:            data.data.oneCustomer.domainID,
                        description:         data.data.oneCustomer.description,
                        autoPayments:        data.data.oneCustomer.autoPayments,
                        membershipDuration:  data.data.oneCustomer.membershipDuration,
  
                    }
                });

            }else{
                this.parent.setState({ apolloError:"", allCountries,allMembershipDurations,allDomains,formData:{...this.parent.state.formData, countryID, deliveryCountryID }});
            }
        }
    }

    /**
     * Přidání nebo úprava nového zákazníka
     * @param {*} addCustomer funkce z apollo, pomocí které se posílají data na server
     */
    async addCustomer(addCustomer){

        const data = this.parent.state.formData;
        let notify = new Notification();   

        if(data.name != "" && data.surname != ""){         
            if(data.street != ""){
                if(data.city != ""){
                    if(data.zip != ""){
                        if(data.countryID != 0){
                            if(data.domainID != 0){

                                //if((data.customerID == 0 && data.membershipDuration != 0) || data.customerID != 0){

                                    const helper = new Helper();
                                    if(data.email != "" && helper.validateEmail(data.email)){

                                        data.autoPayments = parseInt(data.autoPayments);

                                        var custEmail = await this.parent.props.client.query({ 
                                            query: GET_CUSTOMER_BY_EMAIL,
                                            errorPolicy:"all",
                                            variables:{email:data.email, customerID:data.customerID}, 
                                            fetchPolicy: 'network-only'
                                        });
                                
                                        if(custEmail.errors){
                                            this.parent.setState({apolloError:custEmail.errors[0].message});
                                        }else{
                                            if(custEmail.data && custEmail.data.customerByEmail){
                                                notify.setNotification(null,'Tento email už používá jiný zákazník',false,true,this.parent.props.client); 
                                            }else{
                                                addCustomer({
                                                    variables:data
                                                });
                                            }
                                        }

                                    }else{
                                        notify.setNotification(null,'Email není ve správném tvaru',false,true,this.parent.props.client); 
                                    }
                                /*
                                }else{
                                    notify.setNotification(null,'Nevybrali jste členství',false,true,this.parent.props.client); 
                                }*/
                                
                            }else{
                                notify.setNotification(null,'Nevyplnili jste doménu',false,true,this.parent.props.client); 
                            }
                        }else{
                            notify.setNotification(null,'Nevyplnili jste stát',false,true,this.parent.props.client); 
                        }
                    }else{
                        notify.setNotification(null,'Nevyplnili jste PSČ',false,true,this.parent.props.client); 
                    }
                }else{
                    notify.setNotification(null,'Nevyplnili jste město',false,true,this.parent.props.client); 
                }
            }else{
                notify.setNotification(null,'Nevyplnili jste ulici',false,true,this.parent.props.client); 
            }
           
        }else{
            notify.setNotification(null,'Nevyplnili jste jméno a příjmení zákazníka.',false,true,this.parent.props.client);
        }

    }

    /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {*} cache apollo cahe v které jsou oložena data
     * @param {*} response položka, která byla vložena nebo upravována
     */

    updateCustomerList(cache,response,selectedCustomerID,customerListVariables){

        const { allCustomers } = cache.readQuery({ query: GET_CUSTOMERS, variables:customerListVariables });

        if(!(selectedCustomerID && selectedCustomerID != 0)){

            cache.writeQuery({ 
                query:GET_CUSTOMERS,
                variables:customerListVariables,
                data:{
                    allCustomers: [response.data.addEditCustomer,...allCustomers]
                } 
            });

        }else{

            var arr = [...allCustomers];
            
            arr.forEach((item,index) => {
                if(item.customerID == response.data.addEditCustomer.customerID){

                    arr[index].customerID         = response.data.addEditCustomer.customerID;
                    arr[index].name               = response.data.addEditCustomer.name;
                    arr[index].surname            = response.data.addEditCustomer.surname;
                    arr[index].company            = response.data.addEditCustomer.company;
                    arr[index].tel                = response.data.addEditCustomer.tel;
                    arr[index].email              = response.data.addEditCustomer.email;
                    arr[index].membership         = response.data.addEditCustomer.membership;
                    arr[index].autoPayments       = response.data.addEditCustomer.autoPayments;
                    arr[index].membershipDuration = response.data.addEditCustomer.membershipDuration;

                    if(response.data.addEditCustomer.membershipTo){
                        arr[index].membershipTo = response.data.addEditCustomer.membershipTo;
                    }
                }

            });

            cache.writeQuery({ 
                query:GET_CUSTOMERS,
                variables:customerListVariables,
                data:{
                    allCustomers: arr
                } 
            });
        }
    }

    /**
     * Update seznamu zákazníků po smazání vybraných zákazníků
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání potahu
     */
    
    updateAfterDeleteCustomer(cache, response,customerListVariables){

        var resp = response.data.deleteCustomers.split(",");

        const { allCustomers } = cache.readQuery({ query: GET_CUSTOMERS,variables:customerListVariables });
        var arr = [...allCustomers];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.customerID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        })
        
    
        cache.writeQuery({ 
            query:GET_CUSTOMERS,
            variables:customerListVariables,
            data:{
                allCustomers: [...arr]
            } 
        });

    }

    /**
     * pomocná funkce pro editor, která ukládá vložený text do popisu
     * @param {*} data text pro popis
     */

    setDescription(data){
        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,["description"]: data}});
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formHandle(e){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({formData:{...this.parent.state.formData,[n]: v}});
              
    }

}

export default Customer;