/*
    Výběr veškerých kategorií
*/
import React,{ Component,useState } from 'react';
import {Query,useMutation,useQuery} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import NoItems from '../../../GlobalComponents/NoItems';
import {GET_MEMBERSHIPS,UPDATE_MEMBERSHIPS_PRIORITY,REGENERATE_MEMBERSHIPS_PRICE} from '../Queries/index.js';
import AddEditMembership from './AddEditMembership';
import Helper from '../../../Library/helper';
import edit from '../../../Public/Images/edit.svg';
import move from '../../../Public/Images/move.svg';
import { withApollo } from 'react-apollo';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ModalNotification from '../../../GlobalComponents/ModalNotification.js';
import Notification from '../../../Library/notification.js';


const AllMemberships = (props) => {


    const [listVariables,SetListVariables] = useState({
        lang:"cz"
    })

    const [regLoading,SetRegLoading] = useState(null);
    const [selectedMembershipID,SetMembershipID] = useState(null);
    const [showRegenerateNotify,SetShowRegenerateNotify] = useState(false);

    const {data,loading,error,refetch} = useQuery(GET_MEMBERSHIPS,{
        variables:listVariables,
        errorPolicy:"all"
    });

    const [updateMembershipPriority] = useMutation(UPDATE_MEMBERSHIPS_PRIORITY,{
        update:() => {
            refetch();
        }
    });

    const [RegenerateMembershipsPrices] = useMutation(REGENERATE_MEMBERSHIPS_PRICE,{
        update:() => {

            SetRegLoading(false);

            let notify = new Notification();
            notify.setNotification(null,'Úspěšně přegenerováno',true,false,props.client);

            refetch();
        }
    });

    const OnDragEnd = (result) => {

        const IDs = result.draggableId.split("-");

        updateMembershipPriority({
            variables:{
                membershipDurationID: IDs[0],
                fromIndex:result.source.index,
                toIndex:  result.destination.index
            }
        });

    }

    const RegeneratePrices = (act) => {
        if(act)
        {
            SetRegLoading(true);
            RegenerateMembershipsPrices();
        }
        SetShowRegenerateNotify(false);
    }

    var err = "";
    if(error)
    {
        const helper = new Helper(); 
        err = helper.getApolloErrorText(error);
    }
                
    return(
        <div id="settings" className="whole-container" >
            
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main">
                        
                        <div className="card-header d-flex align-items-center">
                            Kurzy / Členství
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={(e) => SetShowRegenerateNotify(true)}>Přegenerovat ceny všech</button>
                            <button className="btn btn-primary btn-thiner ml-2" onClick={(e) => SetMembershipID(0)}>Přidat</button>
                        </div>
                        <div className="card-body">
                             
                            {loading || regLoading ?
                                <Loading />
                            :
                                <>
                                    {err ?                                            
                                        <Error text={err} />
                                    :
                                        <div>
                                            <div className="data-list">
                                                
                                                <div className="data-list-item header">
                                                    <div className="data-list-item-content">
                                                        <div>Název kurzu / členství</div>
                                                        <div className="text-right">Možnosti</div>
                                                    </div>
                                                </div>


                                                <DragDropContext onDragEnd={(result) => OnDragEnd(result)}>
                                                    <Droppable droppableId="droppable">
                                                        {(provided, snapshot) => (
                                                            <div
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}
                                                            >
                                                                {data.allAdminMembershipDurations && data.allAdminMembershipDurations.map((item,index) => {
                                                                    
                                                                    return (
                                                                        
                                                                        <Draggable key={index} draggableId={item.membershipDurationID} index={index}>
                                                                            {(provided, snapshot) => (
                                                                            <div className="data-list-item" 
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                            >
                                                                                
                                                                                    <div className="data-list-item-content categories">
                                                                                        <div className="cross" style={{paddingLeft:10}}>
                                                                                            <img className="move-icon" src={move} />
                                                                                            {item.name}
                                                                                        </div>
                                                                                        <div className="text-right">
                                                                                            <img onClick={() => SetMembershipID(item.membershipDurationID)} className="edit-icon" src={edit} /> 
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    )

                                                                })}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                                
                                                                
                                            </div>
                                            {data.allAdminMembershipDurations && data.allAdminMembershipDurations.length == 0 &&
                                                <NoItems text={"Momentálně se zde nenachází žádné položky."} />
                                            }
                                            {(selectedMembershipID || selectedMembershipID == 0) &&
                                                <AddEditMembership 
                                                    listVariables={listVariables} 
                                                    openCloseModal={SetMembershipID} 
                                                    selectedMembershipID={selectedMembershipID} 
                                                    refetch = {refetch}
                                                />
                                            }
                                            {showRegenerateNotify ?
                                                <ModalNotification 
                                                    yesNo={true} 
                                                    callback={RegeneratePrices} 
                                                    text={"Opravdu chcete přegenerovat ceny všech kurzů/členství ?"}
                                                />
                                            :null}
                                        </div>
                                    }
                                </>
                            }
                                  
                        </div>
                    </div>
                </div>
            </div> 			
        </div>
    
    );

    

}


export default withApollo(AllMemberships);