import React,{ Component, Fragment } from 'react';
import {Query,Mutation} from 'react-apollo';
import {GET_CAMPAIGN_EMAILS,DELETE_CAMPAIGN_EMAIL,ADD_CAMPAIGN_EMAIL,UPDATE_CAMPAIGN_EMAIL_PRIORITY} from '../Queries/campaignEmail.js';
import Loading from '../../../GlobalComponents/Loading';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import Error from '../../../GlobalComponents/Error';
import NoItems from '../../../GlobalComponents/NoItems';
import { withApollo } from 'react-apollo';
import move from '../../../Public/Images/move.svg';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Helper from '../../../Library/helper';
import CampaignEmail from '../Library/campaignEmail.js';
import AddEditCampaignEmail from './AddEditCampaignEmail.js';
import edit from '../../../Public/Images/edit.svg';

const INITIAL_STATE = {
    showDeleteNotifi:false,
    showAddCampaignEmail:false,
    deleteCampaignEmail:"",
    campaignEmailID:0,
    selectedCampaignEmailID:0,
    selectedCampaignEmails:[],
    showSearchedForms:false,
    searchedForms:[]
}

class CampaignEmails extends Component{

    constructor(props){
        super(props);

        this.state = INITIAL_STATE;

    }

    componentDidMount(){
    }

    openCloseModal = (type,campaignEmailID) => {
        this.setState({
            showAddCampaignEmail:type,
            selectedCampaignEmailID:campaignEmailID
        })
    }

    /**
     * smazání doporučených článků
     * @param {*} deleteCampaignEmail funkce, která posílá data na server
     * @param {*} campaignEmailID ID doporučeného článku
     */
    
    deleteItems = (e,deleteCampaignEmail,campaignEmailID) => {

        var checked = e.target.checked;
        var arr = [...this.state.selectedCampaignEmails];

        if(!checked){
            for(let i in this.state.selectedCampaignEmails){
                if(this.state.selectedCampaignEmails[i] == campaignEmailID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [campaignEmailID,...arr];
        }

        
        this.setState({
            deleteCampaignEmail,
            selectedCampaignEmails:arr
        });
  
    }

    delItems = (action) => {

        if(action){

            let campaignEmailIDs = this.state.selectedCampaignEmails.join(",");

            this.state.deleteCampaignEmail({
                variables:{
                    campaignEmailIDs: campaignEmailIDs,
                    campaignID:this.props.campaignID
                }
            })
        }
        this.setState(INITIAL_STATE);
    }

    showDelNotifi = () => {

        if(this.state.selectedCampaignEmails.length > 0){
            this.setState({
                showDeleteNotifi:true
            });
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste email.',false,true,this.props.client);
        }
    }
    
    onDragEnd = (result,updateCampaignEmailPriority) => {

        const IDs = result.draggableId.split("-");

        updateCampaignEmailPriority({
            variables:{
                campaignID: this.props.campaignID,
                campaignEmailID: IDs[0],
                fromIndex:result.source.index,
                toIndex:  result.destination.index
            }
        });

    }

    render(){

        const {campaignID} = this.props;
        const {selectedCampaignEmails} = this.state;

        const listVariables = {campaignID,lang:"cz"};

        return(
            <div>
                
                <Query 
                    query={GET_CAMPAIGN_EMAILS} 
                    variables={listVariables} 
                    fetchPolicy = 'network-only' 
                >
                {({data,loading,error,refetch}) => {

                    if(loading) return (<Loading />);
                    if(error) return (<Error text={error} />);
                
                    return(

                        <Mutation 
                            mutation={DELETE_CAMPAIGN_EMAIL}
                            
                            update = {(cache, response) => {

                                let ar = new CampaignEmail();
                                ar.updateAfterDelete(cache, response,listVariables);

                                let notify = new Notification();
                                notify.setNotification(cache,'Úspěšně smazáno',true,false);

                            }}
                        >   
                        {(deleteCampaignEmail) => {    

                            return(

                                <Fragment>

                                    <div className="d-flex align-items-center">
                                        
                                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => this.openCloseModal(true)}>Přidat email</button>
                                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => this.showDelNotifi()}>Smazat označené</button>
                                        
                                    </div>
 
                                    <div className="data-list mt-2">

                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>Předmět emailu</div>
                                                <div className="text-right">Možnosti</div>
                                            </div>
                                        </div>

                                        <Mutation 
                                            mutation={UPDATE_CAMPAIGN_EMAIL_PRIORITY} 
                                            update={() => {
                                                refetch();
                                            }}
                                        >
                                        {
                                            (updateCampaignEmailPriority,{error}) => {

                                                if(error){
                                                    const helper = new Helper(); 
                                                    return (<Error text={helper.getApolloErrorText(error)} />);
                                                }

                                                return (
                                                    <DragDropContext onDragEnd={(result) => this.onDragEnd(result,updateCampaignEmailPriority)}>
                                                        <Droppable droppableId="droppable">
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    {...provided.droppableProps}
                                                                    ref={provided.innerRef}
                                                                >
                                                                    {data.allCampaignEmails && data.allCampaignEmails.map((item,index) => {

                                                                        var checked = false;
                                                                        for(let i in selectedCampaignEmails){
                                                                            if(selectedCampaignEmails[i] == item.campaignEmailID)checked = true;
                                                                        }

                                                                        return(
                                                                            <Draggable key={index} draggableId={item.campaignEmailID} index={index}>
                                                                                {(provided, snapshot) => (
                                                                                <div className="data-list-item" 
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                >
                                                                                    <div key={index} className="data-list-item-content">
                                                                                        <div>
                                                                                            
                                                                                            <img className="move-icon" src={move} />
                                                                                            {item.subject}
                                                                                        </div>
                                                                                        <div className="text-right">
                                                                                            <img onClick={() => this.openCloseModal(true,item.campaignEmailID)} className="edit-icon" src={edit} /> 
                                                                                            <input className="delete-checked" type="checkbox" name="deleteCampaignEmail[]" checked={checked} onChange = {(e) => this.deleteItems(e,deleteCampaignEmail,item.campaignEmailID)} />
                                                                                            
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                )}
                                                                            </Draggable>
                                                                        )
                                                                    })}  
                                                                    
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                )
                                        }}
                                        </Mutation>

                                        {data.allCampaignEmails && data.allCampaignEmails.length == 0 &&
                                            <NoItems text={"Momentálně se zde nenachází žádné emaily."} />
                                        }       

                                    </div>

                                    {this.state.showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete odstranit vybrané emaily ?'} callback={this.delItems} /> }
                                    {this.state.showAddCampaignEmail &&
                                        <AddEditCampaignEmail 
                                            openCloseModal={this.openCloseModal} 
                                            campaignID = {campaignID} 
                                            selectedCampaignEmailID={this.state.selectedCampaignEmailID} 
                                            listVariables={listVariables} 
                                            refetch = {refetch}
                                        />
                                    }
                                </Fragment>
                                    
                                
                            )   
                            
                        }}
                        </Mutation>
                          
                    )

                }}

                </Query>

            </div>
        );

    }
}

export default withApollo(CampaignEmails);
