/*
    Přidání kategorie
*/
import React,{ Component,Fragment } from 'react';
import {Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import { withApollo } from 'react-apollo';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import AudioLib from '../Library/audio';
import TinyMCEEditor from '../../TinyMCEFileManager/Components/TinyMCEEditor.js';
import settingsIcon from '../../../Public/Images/settings.svg';
import AudioTranslateSettings from './AudioTranslateSettings';
import ProgressBar from '../../../GlobalComponents/ProgressBar';
import {
    ADD_EDIT_AUDIO,
    GET_AUDIOS,
    GENERATE_AUDIO_LANG,
    GENERATE_LANG_AUDIO_FILE,
    UPDATE_AUDIO
} from '../Queries/index.js';
import { SERVER_URL,AUDIO_URL } from '../../../Config/index.js';
import moment from 'moment';

const INITIAL_STATE = {
    err:"",
    loadingAudio : false,
    audioFiles:{},
    addLoading:false,
    showTranslateSettings:false,
    translatedLangsCount:0,
    translatedHeadline: "",
    selectedAudioHash:"",
    isPlaying:false,
    formData:{
        generateTranslations:{
            enabled:true,
            onlyForParams:[],
            onlyFor:""
        },
        audioID:0,
        categoryID:0,
        membershipDurationID:0,
        audioTypeID:1,
        epizodNumber:"",
        langs:[],
        selectedLang:'cz'
    },
    allLanguageMutations:[],
    allAudioTypes:[],
    allFilterCategories:[],
    allAdminMembershipDurations:[]
}

class AddEditAudio extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
        
        this.audioLib = new AudioLib(this); 

        this.myRef = React.createRef();

        this.PlaySample = this.PlaySample.bind(this);
        this.StopSample = this.StopSample.bind(this);
    }

    componentDidMount(){
        this.audioLib.getData();

        if(this.props.selectedAudioID && this.props.selectedAudioID != 0)
            this.audioLib.SetFormData("generateTranslations",{...this.state.formData.generateTranslations,enabled:false});
    }

    PlaySample()
    {
        this.myRef.current.play();
        this.setState({isPlaying:true});
    }

    StopSample()
    {
        this.myRef.current.pause();
        this.setState({isPlaying:false});
    }

    render(){

        var {
            err,
            loadingAudio,
            audioFiles,
            addLoading,
            showTranslateSettings,
            allLanguageMutations,
            translatedHeadline,
            selectedAudioHash,
            isPlaying
        } = this.state;

        const {selectedAudioID} = this.props;

        return(

            <Mutation 
                mutation={ADD_EDIT_AUDIO}
                errorPolicy = "all"
                refetchQueries = {(data)=>{
                    return [{
                        query:GET_AUDIOS,
                        variables: { lang:"cz" }
                    }]
                }}
                update = {async (cache, response) => {

                    var success = true;

                    if(this.state.formData.generateTranslations.enabled)
                    {
                        this.setState({translatedLangsCount:1,addLoading:true});

                        for(let val of allLanguageMutations)
                        {
                            if(val.suffix != "cz" && val.suffix != "us")
                            {
                                try
                                {
                                    this.setState({translatedHeadline: "Překládám jazyk " + val.suffix + "..."});

                                    var onlyParams = this.state.formData.generateTranslations.onlyForParams;

                                    await this.props.client.mutate({
                                        mutation:GENERATE_AUDIO_LANG,
                                        variables:{
                                            lang: val.suffix,
                                            audioID: response.data.addEditAudio.audioID,
                                            isEdit: (selectedAudioID && selectedAudioID != 0 ? true : false),
                                            onlyForParams:onlyParams
                                        }
                                    });

                                    if(onlyParams.length == 0 || onlyParams.indexOf("audioText") != -1)
                                    {
                                        this.setState({translatedHeadline: "Vytvářím audio " + val.suffix + "..."});

                                        await this.props.client.mutate({
                                            mutation:GENERATE_LANG_AUDIO_FILE,
                                            variables:{
                                                lang: val.suffix,
                                                audioID: response.data.addEditAudio.audioID
                                            }
                                        });
                                    }

                                    this.setState({translatedLangsCount:this.state.translatedLangsCount + (val.suffix == "en" ? 2 : 1)});

                                }
                                catch(err)
                                {
                                    const helper = new Helper(); 
                                    this.setState({
                                        err:helper.getApolloErrorText(err)
                                    });
                                    success = false;
                                    break;
                                }
                            }
                        }

                        try
                        {
                            await this.props.client.mutate({
                                mutation:UPDATE_AUDIO,
                                variables:{
                                    audioData:{
                                        audioID:response.data.addEditAudio.audioID,
                                        dateEdit:moment().format("YYYY-MM-DD HH:mm:ss")
                                    }
                                }
                            });

                            this.setState({addLoading:false});

                        }
                        catch(err)
                        {
                            const helper = new Helper(); 
                            this.setState({
                                err:helper.getApolloErrorText(err)
                            });
                            success = false;
                        }
                    }

                    if(success)
                    {
                        this.props.refetch();

                        let notify = new Notification();

                        if(selectedAudioID && selectedAudioID != 0) notify.setNotification(cache,'Úspěšně uloženo',true,false);
                        else notify.setNotification(cache,'Úspěšně přidáno',true,false);

                        this.props.openCloseModal(false);

                    }

                }}                
            >
            {
                (addEditAudio,{loading, error}) => {

                    const {
                        epizodNumber,
                        selectedLang,
                        audioTypeID,
                        membershipDurationID,
                        generateTranslations
                    } = this.state.formData;

                    const {allLanguageMutations,allAdminMembershipDurations,allAudioTypes} = this.state;
                    
                    if(error){
                        const helper = new Helper(); 
                        err = helper.getApolloErrorText(error);
                    }

                    const langFile = this.audioLib.getLangValue("file") && this.audioLib.getLangValue("file").name;

                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => !loading && !addLoading && this.props.openCloseModal(false)}
                        >
                            <div className="modal-content">
                                <div className="modal-header d-flex align-items-center">
                                    <h4 className="modal-title">{(selectedAudioID && selectedAudioID != 0 ? "Úprava nahrávky" : "Přidání nahrávky")}</h4>
                                    {!loading && !addLoading ? 
                                        <>
                                            <div className="select-lang">
                                                <div className={"form-check generate-all-langs" + (!generateTranslations.enabled ? " bigger" : "")}>
                                                    <input 
                                                        className="form-check-input" 
                                                        type="checkbox" 
                                                        name="generateTranslations" 
                                                        id={"generateTranslations"} 
                                                        checked={generateTranslations.enabled} 
                                                        onChange={(e) => {
                                                            this.audioLib.SetFormData("generateTranslations",{...generateTranslations,enabled:e.target.checked},() => {
                                                                this.audioLib.SetFormData("selectedLang","cz");
                                                            });
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor={"generateTranslations"}>
                                                        Vygenerovat všechny překlady
                                                    </label>
                                                    
                                                </div>

                                                {generateTranslations.enabled && selectedAudioID && selectedAudioID != 0 ?
                                                    <img 
                                                        className="cursor-pointer"
                                                        style={{height:"25px",marginLeft:"10px"}} 
                                                        src={settingsIcon} 
                                                        onClick={() => this.setState({showTranslateSettings:!showTranslateSettings})}
                                                    />
                                                :null}
                                                
                                                {!generateTranslations.enabled ?
                                                    <select className="form-control" name="selectedLang" onChange={(e) => this.audioLib.formHandle(e)}>
                                                        {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                                            <option key={index} value={item.suffix}> {item.suffix} </option>
                                                        ))}
                                                    </select>
                                                :null}

                                            </div>
                                            <button type="button" className="close ml-3" onClick={() => this.props.openCloseModal(false)}>                                    
                                                <span aria-hidden="true">x</span>
                                                <span className="sr-only">Close</span>
                                            </button>
                                        </>
                                    :null}
                                    {showTranslateSettings ?
                                        <AudioTranslateSettings 
                                            generateTranslations = {generateTranslations}
                                            parent = {this}
                                        />
                                    :null}
                                </div>
                                <div className="modal-body">

                                    {err ?
                                        <Error text={err} />
                                    :
                                        <Fragment>

                                            {!loading && !addLoading ? 

                                            <div className="row">
                                                <div className="col-12 col-sm-4">
                                                    <label className="input-label">*Kurz / členství</label>
                                                    <div className="form-group">
                                                        
                                                        <select value={membershipDurationID} className="form-control" name="membershipDurationID" onChange={(e) => this.audioLib.formHandle(e)}>

                                                            <option key={0} value={0}> --- vyberte kurz / členství --- </option>
                                                            {allAdminMembershipDurations && allAdminMembershipDurations.map((item,index) => (
                                                                <option key={index} value={item.membershipDurationID}> {item.name} </option>
                                                            ))}

                                                        </select>

                                                    </div>
                                                </div>  
                                                <div className="col-12 col-sm-4">
                                                    <label className="input-label">Název nahrávky ({selectedLang})</label>
                                                    <div className="form-group">
                                                        <input className="form-control" type="text" name="name" value={this.audioLib.getLangValue("name")} onChange={(e) => this.audioLib.formLangHandle(e)} />
                                                    </div>
                                                </div>   
                                                <div className="col-12 col-sm-4">
                                                    <label className="input-label">Číslo epizody</label>
                                                    <div className="form-group">
                                                        <input className="form-control" type="text" name="epizodNumber" value={epizodNumber} onChange={(e) => this.audioLib.formHandle(e)} />
                                                    </div>
                                                </div>   
                                                <div>

                                                <div className="col-12 ">
                                                    <label className="input-label">Typ</label>
                                                    <div className="form-group">
                                                        <select value={audioTypeID} onChange={(e) => this.audioLib.formHandle(e)} className="form-control" name="audioTypeID">
                                                            {allAudioTypes.map((item) => (
                                                                <option value={item.audioTypeID} key={item.audioTypeID}>{item.type}</option>
                                                            ))}
                                                        </select>
                                                        
                                                    </div>
                                                </div>

                                                </div>  

                                                {audioTypeID == 1 ?          
                                                    <div className="col-12">
                                                        <label className="input-label">Text nahrávky ({selectedLang})</label>
                                                        
                                                        {audioFiles && selectedAudioID && selectedAudioID != 0 ?
                                                            <div className="form-group">
                                                                <label>
                                                                    Název nahraného souboru: {audioFiles[selectedLang]}
                                                                </label>
                                                                <audio 
                                                                    onEnded={() => this.setState({isPlaying:false})}
                                                                    key={selectedLang}
                                                                    src={SERVER_URL + "/" + AUDIO_URL + "/" + selectedAudioHash + "/" + audioFiles[selectedLang]} 
                                                                    ref={this.myRef}
                                                                ></audio>
                                                                {audioFiles[selectedLang] ?
                                                                    <button 
                                                                        className={"btn btn-thiner ml-2" + (isPlaying ? " btn-warning" : " btn-primary")}
                                                                        onClick={() => (isPlaying ? this.StopSample() : this.PlaySample())}
                                                                    >
                                                                        {(isPlaying ? "Zastavit" : "Přehrát")}
                                                                    </button>
                                                                :null}
                                                            </div>
                                                        :null} 

                                                        <div className="form-group">
                                                            <TinyMCEEditor 
                                                                allServices = {[]} 
                                                                allForms = {[]} 
                                                                onChange={(content,type) => this.audioLib.formLangHandleEditor(content,"audioText")} 
                                                                initialValue={this.audioLib.getLangValue("audioText")} />
                                                            
                                                        </div>
                                                        
                                                    </div>   
                                                :null}

                                                {audioTypeID == 2 ?
                                                    <div className="col-12">
                                                        <label className="input-label">ID Vimeo videa ({selectedLang})</label>
                                                        <button 
                                                            onClick={() => this.audioLib.CopyVimeoIDToAllLangs(this.audioLib.getLangValue("videoUrl","cz"))}
                                                            className="btn btn-primary btn-thiner ml-2"
                                                        >Zkopírovat z CZ do všech jazyků</button>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="videoUrl" value={this.audioLib.getLangValue("videoUrl")} onChange={(e) => this.audioLib.formLangHandle(e)} />
                                                        </div>
                                                    </div>   
                                                :null}     

                                                <div className="col-12">
                                                    <label className="input-label">Popis ({selectedLang})</label>
                                                    <div className="form-group">
                                                        <TinyMCEEditor 
                                                            allServices = {[]} 
                                                            allForms = {[]} 
                                                            onChange={(content,type) => this.audioLib.formLangHandleEditor(content,type)} 
                                                            initialValue={this.audioLib.getLangValue("text")} />
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            :

                                                <div>
                                                    {generateTranslations.enabled ?
                                                        <div>
                                                            <p className = "text-center">{translatedHeadline}</p>
                                                            <ProgressBar
                                                                progressValue = {this.state.translatedLangsCount}
                                                                totalValue = {allLanguageMutations.length}
                                                            />
                                                        </div>
                                                    :
                                                        <Loading />  
                                                    }
                                                </div>  

                                            }  
                                        </Fragment>
                                    }
                                            
                                </div>
                                <div className="modal-footer"> 
                                    {!loading && !addLoading ? 
                                        <>
                                            <button type="button" className="btn btn-primary flex-fill" onClick={() => this.audioLib.addAudio(addEditAudio)}>{(selectedAudioID && selectedAudioID != 0 ? "Upavit" : "Přidat")}</button>
                                            <button type="button" className="btn btn-danger" onClick={() => this.props.openCloseModal(false)}>Zavřít</button>
                                        </>
                                    :null}
                                </div>
                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(AddEditAudio);