/*
    Výběr veškerých kategorií
*/
import React,{ Component } from 'react';
import {Query,Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import NoItems from '../../../GlobalComponents/NoItems';
import {UPDATE_AUDIO_PRIORITY,GET_AUDIOS,DELETE_AUDIOS} from '../Queries/index.js';
import AddEditAudio from './AddEditAudio';
import Helper from '../../../Library/helper';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import edit from '../../../Public/Images/edit.svg';
import move from '../../../Public/Images/move.svg';
import Notification from '../../../Library/notification';
import AudioLib from '../Library/audio';
import { withApollo } from 'react-apollo';

const INITIAL_STATE = {
    showDeleteCategoryNotifi : false,
    showDoNotDelete:false,
    showAddCategory:false,
    selectedAudioID:0,
    audioID:0,
    deleteCat:null,
    selectedItems:[],
    showAddCategory : false,
    showDeleteNotifi:false
}

class allAudios extends Component{


    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
    }


    delCategory = (action) => {
        if(action){
            let audioIDs = this.state.selectedItems.join(",");
            this.state.deleteAudios({
                variables:{
                    audioIDs
                }
            });
            
            this.setState(INITIAL_STATE);
        }else{
            this.setState({
                showDeleteNotifi:false
            });
        }
    }

    showDoNotDel = (action) => {
        this.setState({
            showDoNotDelete:action
        });
    }

    openCloseModal = (type,audioID) => {
        this.setState({
            showAddCategory:type,
            selectedAudioID:audioID
        })
    }
    
    /**
     * smazání kategorie
     * @param {*} deleteAudios funkce, která posílá data na server
     * @param {*} audioID ID kategorie
     */
    
    checkItemToDelete = (e,deleteAudios,audioID) => {

        var checked = e.target.checked;
        var arr = [...this.state.selectedItems];

        if(!checked){
            for(let i in this.state.selectedItems){
                if(this.state.selectedItems[i] == audioID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [audioID,...arr];
        }
        
        this.setState({
            deleteAudios,
            selectedItems:arr
        });
  
    }
    
    openCloseAddCategoryModal = (type) => {
        this.setState({
            showAddCategory:type,
        })
    }
    
    showDelNotifi = () => {

        if(this.state.selectedItems.length > 0){
            this.setState({
                showDeleteNotifi:true
            });
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste žádnou položku.',false,true,this.props.client);
        }
    }
    onDragEnd = (result,updateCategoryPriority) => {

        const IDs = result.draggableId.split("-");

        updateCategoryPriority({
            variables:{
                audioID: IDs[0],
                fromIndex:result.source.index,
                toIndex:  result.destination.index
            }
        });

    }

    render(){
        
        const {selectedItems} = this.state;

        return(
            <div id="settings" className="whole-container" >
            	
                <div className="row" >
                    <div className="col-sm one">
                        <div className="card main">
                            
                            <div className="card-header d-flex align-items-center">
                                Seznam audio nahrávek
                                <button className="btn btn-primary btn-thiner ml-auto" onClick={() => this.openCloseModal(true)}>Přidat</button>
                                <button className="btn btn-danger btn-thiner ml-2" onClick={() => this.showDelNotifi()}>Smazat označené</button>
                            </div>
                            <div className="card-body">
                                <Query query={GET_AUDIOS} errorPolicy="all" variables={{ lang:"cz" }}>
                                    {
                                        ({data,loading,error,refetch }) => {

                                            console.log(data);

                                            if(error){
                                                const helper = new Helper(); 
                                                return (<Error text={helper.getApolloErrorText(error)} />);
                                            }
                                            if(loading) return (<Loading />);
                                            
                                            return(
                                                <div>
                                                    <div className="data-list">
                                                        
                                                            <div className="data-list-item header">
                                                                <div className="data-list-item-content">
                                                                    <div>Název skladby</div>
                                                                    <div className="text-center">Kurz / členství</div>
                                                                    <div className="text-center">Č. epizody</div>
                                                                    <div className="text-right">Možnosti</div>
                                                                </div>
                                                            </div>

                                                            <Mutation
                                                                mutation = {DELETE_AUDIOS}
                                                                update = {async (cache, response) => {
                                                                    
                                                                    let notify = new Notification();
                                                                    notify.setNotification(cache,'Úspěšně smazáno',true,false);
                                                
                                                                }}
                                                                refetchQueries = {(data)=>{
                                                                    return [{
                                                                        query:GET_AUDIOS,
                                                                        variables: { lang:"cz" }
                                                                    }]
                                                                }}
                                                                onError = {(error) => {
                                                                    const helper = new Helper(); 
                                                                    let notify = new Notification();
                                                                    notify.setNotification(null,helper.getApolloErrorText(error),false,true,this.props.client);
                                                                }}
                                                            >
                                                                {
                                                                    (deleteAudios,response) => {
                                                                
                                                                        return(
                                                                            <Mutation 
                                                                                mutation={UPDATE_AUDIO_PRIORITY} 
                                                                                update={() => {
                                                                                    refetch();
                                                                                }}
                                                                            >
                                                                            {
                                                                                (updateCategoryPriority,{error}) => {

                                                                                    if(error){
                                                                                        const helper = new Helper(); 
                                                                                        return (<Error text={helper.getApolloErrorText(error)} />);
                                                                                    }

                                                                                    return (
                                                                                        <DragDropContext onDragEnd={(result) => this.onDragEnd(result,updateCategoryPriority)}>
                                                                                            <Droppable droppableId="droppable">
                                                                                                {(provided, snapshot) => (
                                                                                                    <div
                                                                                                        {...provided.droppableProps}
                                                                                                        ref={provided.innerRef}
                                                                                                    >
                                                                                                        {data.allAudios && data.allAudios.map((item,index) => {
                                                                                                            
                                                                                                            if(item.audioID){
                                                                                                                
                                                                                                                var checked = false;
                                                                                                                for(let i in selectedItems){
                                                                                                                    if(selectedItems[i] == item.audioID)checked = true;
                                                                                                                }

                                                                                                                return (
                                                                                                                    <Draggable key={index} draggableId={item.audioID} index={index}>
                                                                                                                        {(provided, snapshot) => (
                                                                                                                        <div className="data-list-item" 
                                                                                                                            ref={provided.innerRef}
                                                                                                                            {...provided.draggableProps}
                                                                                                                            {...provided.dragHandleProps}
                                                                                                                        >
                                                                                                                            <div className="data-list-item-content categories">
                                                                                                                                <div className="cross" style={{paddingLeft:10}}>
                                                                                                                                    <img className="move-icon" src={move} />
                                                                                                                                    {item.name}
                                                                                                                                </div>
                                                                                                                                <div className="text-center">{item.membership && item.membership.name}</div>
                                                                                                                                <div className="text-center">{item.epizodNumber}</div>
                                                                                                                                <div className="text-right">
                                                                                                                                    <img onClick={() => this.openCloseModal(true,item.audioID)} className="edit-icon" src={edit} /> 
                                                                                                                                    <input className="delete-checked" type="checkbox" name="audiosToDelete[]" checked={checked} onChange = {(e) => this.checkItemToDelete(e,deleteAudios,item.audioID)} />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        )}
                                                                                                                    </Draggable>
                                                                                                                )
                                                                                                            }else{
                                                                                                                return "";
                                                                                                            }
                                                                                                        })}
                                                                                                        {provided.placeholder}
                                                                                                    </div>
                                                                                                )}
                                                                                            </Droppable>
                                                                                        </DragDropContext>
                                                                                    )
                                                                            }}
                                                                            </Mutation>
                                                                        )
                                                                    }
                                                                }
                                                            </Mutation>
                                                        
                                                    </div>
                                                    {data.allAudios && data.allAudios.length == 0 &&
                                                        <NoItems text={"Momentálně se zde nenachází žádné položky."} />
                                                    }
                                                    {this.state.showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat vybrané položky ?'} callback={this.delCategory} /> }
                                                    {this.state.showAddCategory &&
                                                        <AddEditAudio 
                                                            openCloseModal={this.openCloseModal} 
                                                            selectedAudioID={this.state.selectedAudioID} 
                                                            refetch = {refetch}
                                                        />
                                                    }
                                                </div>
                                            );
                                        }
                                    }
                                </Query>
                            </div>
                        </div>
                    </div>
                </div> 			
            </div>
        
        );

    }

}


export default withApollo(allAudios);