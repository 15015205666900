import React,{ Component } from 'react';
import AllLinks from './AllLinks.js';
import AddEditLink from './AddEditLink';

const INITIAL_STATE = {
    showAddLink : false,
}

class Link extends Component{

    constructor(props){
       super(props);
       this.state = INITIAL_STATE;
       
    }

    render(){

        return(

            <div id="settings" className="whole-container" >
            	
                <div className="row" >
                    <div className="col-sm one">
                        <div className="card main">
                            
                            <AllLinks />
                            
                        </div>
                    </div>
                </div> 			
            </div>
        );

    }

}

export default Link;