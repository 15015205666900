/*
    Přidání kategorie
*/
import React,{ Component,Fragment } from 'react';
import {Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_SERVICE,GENERATE_SERVICE_LANG} from '../Queries/index.js';
import { withApollo } from 'react-apollo';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import ServiceLib from '../Library/service';
import TinyMCEBasic from '../../TinyMCEFileManager/Components/TinyMCEBasic';
import settingsIcon from '../../../Public/Images/settings.svg';
import ServiceTranslateSettings from './ServiceTranslateSettings';
import ProgressBar from '../../../GlobalComponents/ProgressBar';

const INITIAL_STATE = {
    err:"",
    mainPhoto:"",
    addLoading:false,
    showTranslateSettings:false,
    translatedLangsCount:0,
    formData:{
        generateTranslations:{
            enabled:true,
            onlyForParams:[],
            onlyFor:""
        },
        serviceID:0,
        sellAfterMainProduct:0,
        photo:null,
        langs:[],
        selectedLang:'cz',
        selectedCurrency:'Kč',
        selectedServicesToBuy:[]
    },
    allLanguageMutations:[],
    allServices:[]
}

class AddEditService extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
        
        this.serviceLib = new ServiceLib(this); 
    }

    componentDidMount(){
        this.serviceLib.getData();

        if(this.props.selectedServiceID && this.props.selectedServiceID != 0)
            this.serviceLib.SetFormData("generateTranslations",{...this.state.formData.generateTranslations,enabled:false});
    }    

    render(){

        var {
            err,
            allServices,
            formData,
            addLoading,
            showTranslateSettings,
            allLanguageMutations
        } = this.state;
        var {selectedServiceID,listVariables} = this.props;

        return(

            <Mutation 
                mutation={ADD_EDIT_SERVICE}
                errorPolicy = "all"
                update = {async (cache, response) => {

                    var success = true;

                    if(this.state.formData.generateTranslations.enabled)
                    {
                        this.setState({translatedLangsCount:1,addLoading:true});

                        for(let val of allLanguageMutations)
                        {
                            if(val.suffix != "cz" && val.suffix != "us")
                            {
                                try
                                {
                                    await this.props.client.mutate({
                                        mutation:GENERATE_SERVICE_LANG,
                                        variables:{
                                            lang: val.suffix,
                                            serviceID: response.data.addEditService.serviceID,
                                            isEdit: (this.props.selectedServiceID && this.props.selectedServiceID != 0 ? true : false),
                                            onlyForParams:this.state.formData.generateTranslations.onlyForParams
                                        }
                                    });

                                    this.setState({translatedLangsCount:this.state.translatedLangsCount + (val.suffix == "en" ? 2 : 1)});

                                }
                                catch(err)
                                {
                                    const helper = new Helper(); 
                                    this.setState({
                                        err:helper.getApolloErrorText(err)
                                    });
                                    success = false;
                                    break;
                                }
                            }
                        }

                        this.setState({addLoading:false});
                    }

                    if(success)
                    {
                        this.props.refetch();
                        //this.serviceLib.updateList(cache,response,selectedServiceID,listVariables);

                        let notify = new Notification();

                        if(selectedServiceID && selectedServiceID != 0) notify.setNotification(cache,'Úspěšně uloženo',true,false);
                        else notify.setNotification(cache,'Úspěšně přidáno',true,false);

                        this.props.openCloseModal(false);
                    }

                }}             
            >
            {
                (addEditService,{loading, error}) => {

                    const {
                        selectedLang,
                        selectedCurrency,
                        sellAfterMainProduct,
                        generateTranslations
                    } = this.state.formData;

                    const {allLanguageMutations,mainPhoto} = this.state;
                    
                    if(error){
                        const helper = new Helper(); 
                        err = helper.getApolloErrorText(error);
                    }

                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => !loading && !addLoading && this.props.openCloseModal(false)}
                        >
                            <div className="modal-content">
                                <div className="modal-header d-flex align-items-center">
                                    <h4 className="modal-title">{(selectedServiceID && selectedServiceID != 0 ? "Úprava služby" : "Přidání služby")}</h4>
                                    {!loading && !addLoading ? 
                                        <>
                                            <div className="select-lang">
                                                <div className={"form-check generate-all-langs" + (!generateTranslations.enabled ? " bigger" : "")}>
                                                    <input 
                                                        className="form-check-input" 
                                                        type="checkbox" 
                                                        name="generateTranslations" 
                                                        id={"generateTranslations"} 
                                                        checked={generateTranslations.enabled} 
                                                        onChange={(e) => {
                                                            this.serviceLib.SetFormData("generateTranslations",{...generateTranslations,enabled:e.target.checked},() => {
                                                                this.serviceLib.SetFormData("selectedLang","cz");
                                                            });
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor={"generateTranslations"}>
                                                        Vygenerovat všechny překlady
                                                    </label>
                                                    
                                                </div>

                                                {generateTranslations.enabled && selectedServiceID && selectedServiceID != 0 ?
                                                    <img 
                                                        className="cursor-pointer"
                                                        style={{height:"25px",marginLeft:"10px"}} 
                                                        src={settingsIcon} 
                                                        onClick={() => this.setState({showTranslateSettings:!showTranslateSettings})}
                                                    />
                                                :null}
                                                
                                                {!generateTranslations.enabled ?
                                                    <select className="form-control" name="selectedLang" onChange={(e) => this.serviceLib.formLangObjectHandle(e)}>
                                                        {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                                            <option key={index} value={item.languageID}> {item.suffix} </option>
                                                        ))}
                                                    </select>
                                                :null}
                                            </div>
                                            <button type="button" className="close ml-3" onClick={() => this.props.openCloseModal(false)}>                                    
                                                <span aria-hidden="true">x</span>
                                                <span className="sr-only">Close</span>
                                            </button>
                                        </>
                                    :null}
                                    {showTranslateSettings ?
                                        <ServiceTranslateSettings 
                                            generateTranslations = {generateTranslations}
                                            parent = {this}
                                        />
                                    :null}
                                </div>
                                <div className="modal-body">

                                    {err ?
                                        <Error text={err} />
                                    :
                                        <Fragment>
                                            {!loading && !addLoading ?
                                            <div className="row">  
                                                <div className="col-12 col-sm-8">

                                                    <div className="row"> 

                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <label className="input-label">Nabídnout tento produkt po prodeji hlavního produktu</label>
                                                                <div className="form-group">
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" name="sellAfterMainProduct" id="sellAfterMainProduct1" value="1" checked={(sellAfterMainProduct == 1 ?  true : false)} onChange={(e) => this.serviceLib.formHandle(e)} />
                                                                        <label className="form-check-label" htmlFor="sellAfterMainProduct1">
                                                                            Ano
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" name="sellAfterMainProduct" id="sellAfterMainProduct2" value="0" checked={(sellAfterMainProduct == 0 ?  true : false)} onChange={(e) => this.serviceLib.formHandle(e)} />
                                                                        <label className="form-check-label" htmlFor="sellAfterMainProduct2">
                                                                            Ne
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div> 
                                                        </div>

                                                        <div className="col-12">
                                                            <label className="input-label">Fotka</label>
                                                            {mainPhoto ?
                                                                <div className="col-12 col-sm-4 mb-4 no-padding">
                                                                    <img className = "maxwidth-100" src={mainPhoto} />
                                                                </div>
                                                            :null}
                                                            <div className="form-group input-group">
                                                                <div className="custom-file">
                                                                    
                                                                    <input
                                                                        type="file"
                                                                        className="custom-file-input"
                                                                        id="customFile"
                                                                        name="photo"
                                                                        required
                                                                        multiple
                                                                        onChange={(e) => this.serviceLib.openImage(e)}
                                                                    />
                                                                    <label className="custom-file-label" htmlFor="customFile">Vyberte fotku</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <label className="input-label">Název služby ({selectedLang})</label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="name" value={this.serviceLib.getLangValue("name")} onChange={(e) => this.serviceLib.formLangHandle(e)} />
                                                            </div>
                                                        </div>  
                                                        <div className="col-6">
                                                            <label className="input-label">Cena služby ({selectedLang})</label>
                                                            <div className="form-group">
                                                                <div className="input-group">
                                                                    <input className="form-control" type="number" step=".1" name="price" value={this.serviceLib.getLangValue("price")} onChange={(e) => this.serviceLib.formLangHandle(e)} />
                                                                    
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text">{selectedCurrency}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <label className="input-label">Zlevněná cena ({selectedLang})</label>
                                                            <div className="form-group">
                                                                <div className="input-group">
                                                                    <input className="form-control" type="number" step=".1" name="discountPrice" value={this.serviceLib.getLangValue("discountPrice")} onChange={(e) => this.serviceLib.formLangHandle(e)} />
                                                                    
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text">{selectedCurrency}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <label className="input-label">Popis v upsell bomb ({selectedLang})</label>
                                                            <div className="form-group">
                                                                <TinyMCEBasic 
                                                                    name="upSellBombDescription"
                                                                    value={this.serviceLib.getLangValue("upSellBombDescription")}
                                                                    OnEditorChange={(content) => this.serviceLib.formLangHandleEditor(content,"upSellBombDescription")}
                                                                />
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <label className="input-label">Text emailu ({selectedLang})</label>
                                                            <div className="form-group">
                                                                <TinyMCEBasic 
                                                                    name="emailText"
                                                                    value={this.serviceLib.getLangValue("emailText")}
                                                                    OnEditorChange={(content) => this.serviceLib.formLangHandleEditor(content,"emailText")}
                                                                />
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-4">
                                                    <div className="form-group">
                                                        <label className="input-label">Po nákupu produktu nabídnout tyto:</label>
                                                        {allServices.length > 0 && allServices.map((item,index) => {

                                                            if(item.serviceID != selectedServiceID){

                                                                var checked = false;
                                                                for(let i in formData.selectedServicesToBuy){
                                                                    if(formData.selectedServicesToBuy[i] == item.serviceID)checked = true;
                                                                }

                                                                return(

                                                                    <div key={index} className="form-check">
                                                                        <input className="form-check-input" checked = {checked} type="checkbox" id={"serviceToBuyID-" + index} name="serviceToBuyID[]"  onChange={(e) => this.serviceLib.selectService(e,item.serviceID)} />
                                                                        <label className="form-check-label" htmlFor={"serviceToBuyID-" + index}>{item.name}</label>
                                                                    </div>
    
                                                                )
                                                            }else{
                                                                return "";
                                                            }

                                                        })}
                                                    </div>
                                                </div>
                                            </div>

                                            :

                                                <div>
                                                    {generateTranslations.enabled ?
                                                        <div>
                                                            <p className = "text-center">Překládám...</p>
                                                            <ProgressBar
                                                                progressValue = {this.state.translatedLangsCount}
                                                                totalValue = {allLanguageMutations.length}
                                                            />
                                                        </div>
                                                    :
                                                        <Loading />  
                                                    }
                                                </div>   

                                            }  
                                        </Fragment>
                                    }
                                            
                                </div>
                                <div className="modal-footer"> 
                                    {!loading && !addLoading ? 
                                        <>
                                            <button type="button" className="btn btn-primary flex-fill" onClick={() => this.serviceLib.addService(addEditService)}>{(selectedServiceID && selectedServiceID != 0 ? "Upravit" : "Přidat")}</button>}
                                            <button type="button" className="btn btn-danger" onClick={() => this.props.openCloseModal(false)}>Zavřít</button>
                                        </>
                                    :null}
                                </div>
                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(AddEditService);