import gql from 'graphql-tag';

export const GET_CAMPAIGN_CONTACTS = gql`
query GetCampaignContacts($campaignID: ID!,$searchText:String,$limit:Int,$offset:Int,$lang:String){
    allCampaignContacts(campaignID:$campaignID,searchText:$searchText,limit:$limit,offset:$offset,lang:$lang){
        campaignContactID
        campaignID
        sendDate
        email
        lang
        isFinished
        dateAdd
        dateUnsubscribe
        unsubscribeByAdminID
   }
   allLanguageMutations(onlyEnabled:true){
        languageID
        title
        suffix
        generateNiceTitle
        langTitle
        decimal
        priority
        main
        currencyTitle
    }
}
`;
export const UNSUBSCRIBE_CAMPAIGN_CONTACTS = gql`
    mutation campaignUnsubscribe($campaignContactIDs:ID!) {
        unsubscribeCampaigns(campaignContactIDs:$campaignContactIDs){
            campaignContactIDs
            isFinished
            dateUnsubscribe
            unsubscribeByAdminID
        }
    }
`;
export const ADD_CAMPAIGN_CONTACTS = gql`
    mutation AddCampaifnContacts($emails:String,$campaignID: ID,$lang:String) {
        addCampaignContacts(emails:$emails,lang:$lang,campaignID:$campaignID){
            error
            contacts{
                campaignContactID
                campaignID
                sendDate
                email
                isFinished
                dateAdd
                dateUnsubscribe
                unsubscribeByAdminID
            }
        }
    }
`;