import React,{ Component,Fragment,useState,useEffect } from 'react';
import {Mutation,Query, useQuery} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import NewsletterLib from '../Library/newsletter';
import TinyMCEEditor from '../../TinyMCEFileManager/Components/TinyMCEEditor';
import Helper from '../../../Library/helper';
import Notification from '../../../Library/notification';
import {withApollo} from 'react-apollo';
import DatePicker from 'react-datepicker';
import edit from '../../../Public/Images/edit.svg';
import sluzby from '../../../Public/Images/sluzby.jpg';
import NoItems from '../../../GlobalComponents/NoItems';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import {SERVER_URL,NEWSLETTER_IMAGE_PATH} from '../../../Config/index';
import ProgressBar from '../../../GlobalComponents/ProgressBar';
import Modal from 'react-modal';
import settingsIcon from '../../../Public/Images/settings.svg';
import NewsletterTranslateSettings from './NewsletterTranslateSettings';
import {
    SEND_NEWSLETTER,
    GET_NEWSLETTER_DATA,
    DELETE_NEWSLETTERS,
    GENERATE_NEWSLETTER_LANG
} from '../Queries/newsletter';

const Newsletter = (props) => {

    const INITIAL_DATA_TO_DELETE = {
        selectedNewsletters:[],
        deleteNewsletters:null
    }

    const INITIAL_DATA_TO_NEWSLETTER = {
        newsletterID:0,
        selectedLang:"cz",
        subject:"",
        text:"",
        preheader:"",
        photo:null,
        uploadedPhoto:"",
        langs: [],
        testEmail:"sidris@laskyplnysvet.cz",
        isPlanned:0,
        campaignID:0,
        plannedDate: new Date(),
        generateTranslations:{
            enabled:false,
            onlyForParams:[]
        }
    }

    const variables = {
        lang:"cz"
    }

    let newsletterLib = new NewsletterLib(props);

    const [formData,setFormData] = useState(INITIAL_DATA_TO_NEWSLETTER);

    const [mainPhoto,setMainPhoto] = useState("");
    const [showDeleteNotifi,SetShowDeleteNotifi] = useState(false);
    const [dataToDelete,SetDataToDelete] = useState(INITIAL_DATA_TO_DELETE);
    const [showLayout, setShowLayout] = useState(false);
    const [showStatistic, setShowStatistic] = useState(false);
    const [translatedLangsCount, SetTranslatedLangsCount] = useState(0);
    const [translateLoading, SetTranslateLoading] = useState(false);
    const [showTranslateSettings, SetShowTranslateSettings] = useState(false);

    const {data,loading,error,refetch} = useQuery(GET_NEWSLETTER_DATA,{
        variables:variables
    });

    useEffect(() => {

        if(data && formData.langs.length == 0)
        {
            var langs = [];
            for(let l of data.allLanguageMutations)
            {
                langs.push({
                    subject:"",                    
                    preheader:"",
                    text:"",
                    lang:l.suffix
                });
            }   

            setFormData({...formData,langs:langs});
        }

    },[data,formData.langs])

    const deleteNewsletter = (e,deleteNewsletters,newsletterID) => {

        var checked = e.target.checked;
        var arr = [...dataToDelete.selectedNewsletters];

        if(!checked){
            for(let i in dataToDelete.selectedNewsletters){
                if(dataToDelete.selectedNewsletters[i] == newsletterID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [newsletterID,...arr];
        }

        SetDataToDelete({
            deleteNewsletters,
            selectedNewsletters:arr
        });
  
    }

    const delNewsletter = (action) => {

        if(action){

            let newsletterIDs = dataToDelete.selectedNewsletters.join(",");

            dataToDelete.deleteNewsletters({
                variables:{
                    ids: newsletterIDs
                }
            })
        }
        SetShowDeleteNotifi(false);
        SetDataToDelete(INITIAL_DATA_TO_DELETE);
    }

    const showDelNotifi = () => {

        if(dataToDelete.selectedNewsletters.length > 0){
            SetShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste newsletter.',false,true,props.client);
        }
    }

    const editNewsletter = (item) => {

        var langs = [];
        for(let l of item.langs)
        {
            langs.push({
                lang:l.lang,
                subject:l.subject,
                preheader:l.preheader,
                text:l.text
            })
        }

        setFormData({...formData,
            newsletterID:  item.newsletterID,
            photo:         null,
            uploadedPhoto: item.photo,
            langs:         langs,
            testEmail:     item.testEmail,
            isPlanned:     1,
            plannedDate:   new Date(item.plannedDate),
            campaignID:    item.campaignID
        });

        if(item.photo != ""){
            setMainPhoto(SERVER_URL + "/" + NEWSLETTER_IMAGE_PATH + "/stredni_" + item.photo);
        }else{
            setMainPhoto("");
        }

    }

    const chooseLayout = (layoutID) => {

        setFormData({...formData,text:newsletterLib.getLayoutContent(layoutID)});
        setShowLayout(false);

    }

    var langSocial = [];
    if(data && data.emailCountInNewsletter)
    {
        for(let i = 0; i < data.emailCountInNewsletter.length; i++){

            var check = false;
            for(let j = 0; j < langSocial.length; j++){
                if(langSocial[j].lang == data.emailCountInNewsletter[i].lang){
                    check = true;

                    langSocial[j].count += data.emailCountInNewsletter[i].count;
                    if(data.emailCountInNewsletter[i].socialNetwork){
                        langSocial[j].social.push({
                            count:data.emailCountInNewsletter[i].count,
                            name:data.emailCountInNewsletter[i].socialNetwork.name
                        });
                    }

                }
            }

            if(!check){

                var social = [];
                if(data.emailCountInNewsletter[i].socialNetwork){
                    social.push({
                        count:data.emailCountInNewsletter[i].count,
                        name:data.emailCountInNewsletter[i].socialNetwork.name
                    });
                }

                langSocial.push({
                    lang:data.emailCountInNewsletter[i].lang,
                    count:data.emailCountInNewsletter[i].count,
                    social
                })
            }
        }
    }

    return(
        <div id="settings" className="whole-container" >
            	
            <div className="row" >
                <div className="col-sm one">

                    <Mutation 
                        mutation={SEND_NEWSLETTER}
                        onCompleted = {async (response) => {

                            var success = true;

                            if(formData.generateTranslations.enabled && !response.sendOneNewsletter.isTest)
                            {
                                SetTranslatedLangsCount(1);
                                SetTranslateLoading(true);

                                for(let val of data.allLanguageMutations)
                                {
                                    if(val.suffix != "cz" && val.suffix != "us")
                                    {
                                        try
                                        {
                                            await props.client.mutate({
                                                mutation:GENERATE_NEWSLETTER_LANG,
                                                variables:{
                                                    lang: val.suffix,
                                                    newsletterID: response.sendOneNewsletter.newsletterID,
                                                    isEdit: (formData.newsletterID && formData.newsletterID != 0 ? true : false),
                                                    onlyForParams:formData.generateTranslations.onlyForParams
                                                }
                                            });

                                            SetTranslatedLangsCount((prevVal) => {
                                                return prevVal + (val.suffix == "en" ? 2 : 1)
                                            });

                                        }
                                        catch(err)
                                        {
                                            const helper = new Helper(); 
                                            this.setState({
                                                err:helper.getApolloErrorText(err)
                                            });
                                            success = false;
                                            break;
                                        }
                                    }
                                }

                                SetTranslateLoading(false);
                            }

                            setFormData({...formData,newsletterID:response.sendOneNewsletter.newsletterID});

                            let notify      = new Notification();
                            notify.setNotification(null,"Úspěšně odesláno",true,false,props.client);

                            refetch();

                            
                        }}

                        onError = {(err) => {
                            
                            const helper    = new Helper(); 
                            err             = helper.getApolloErrorText(err);
                            let notify      = new Notification();
                            notify.setNotification(null,err,false,true,props.client);
                            
                        }}
                        
                    >
                    {
                        (sendNewsletter,{data:dataAdd,loading:loadingAdd,error:errorAdd}) => {

                            return (    
                                <div className="d-flex"> 
                                    <div className="card main" style={{maxWidth:620}}>
                                        <div className="card-header d-flex align-items-center">
                                            <div className="mr-3">Počty emailů:</div> 
                                            <button 
                                                className="btn btn-primary ml-auto btn-thiner"
                                                onClick={() => setShowStatistic(!showStatistic)}
                                            >{showStatistic ? "Schovat" : "Zobrazit"}</button>
                                            
                                            {showStatistic ?
                                                <div className="card-header-absolute-panel">
                                                    
                                                    <ul>
                                                        {langSocial.map((item,index) => {

                                                            return(
                                                                <li key={index} className="form-group"><strong>{item.lang}</strong>: {item.count + (item.social && item.social.length > 0 ? " (" + item.social.map((it,ind) => (it.name + ": " + it.count)) + ")" : "")}</li>
                                                            )

                                                        })}
                                                    </ul>

                                                </div>
                                            :null}

                                        </div>
                                        <div className="card-header d-flex align-items-center">

                                            <div className={"ml-auto mr-3 form-check generate-all-langs" + (!formData.generateTranslations.enabled ? " bigger" : "")}>
                                                <input 
                                                    className="form-check-input" 
                                                    type="checkbox" 
                                                    name="generateTranslations" 
                                                    id={"generateTranslations"} 
                                                    checked={formData.generateTranslations.enabled} 
                                                    onChange={(e) => setFormData({...formData,
                                                        selectedLang:"cz",
                                                        "generateTranslations":{...formData.generateTranslations,
                                                            enabled:e.target.checked
                                                        }
                                                    })} 
                                                />
                                                <label className="form-check-label" htmlFor={"generateTranslations"}>
                                                    Vygenerovat všechny překlady
                                                </label>
                                                
                                            </div> 

                                            {formData.generateTranslations.enabled && formData.newsletterID && formData.newsletterID != 0 ?
                                                <img 
                                                    className="cursor-pointer mr-3"
                                                    style={{height:"25px"}} 
                                                    src={settingsIcon} 
                                                    onClick={() => SetShowTranslateSettings(!showTranslateSettings)}
                                                />
                                            :null}

                                            {!formData.generateTranslations.enabled ?
                                            
                                                <div className="mr-3">
                                                    <select value={formData.selectedLang} className="form-control" name="selectedLang" onChange={(e) => newsletterLib.formHandle(e,formData,setFormData)}>
                                                        {data && data.allLanguageMutations && data.allLanguageMutations.map((item,index) => (
                                                            <option key={index} value={item.suffix}> {item.suffix} </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            :null}

                                            <select value={formData.campaignID} style={{width:"150px"}} className="form-control mr-3" name="campaignID" onChange={(e) =>  newsletterLib.formHandle(e,formData,setFormData)}>
                                                <option value={0}>--- vyber kampaň ---</option>
                                                {data && data.allCampaigns.map((item,index) => (
                                                    <option key={item.campaignID} value={item.campaignID}>{item.name}</option>
                                                ))}
                                            </select>
                                            <button className="btn btn-primary" onClick={() => {setFormData(INITIAL_DATA_TO_NEWSLETTER); setMainPhoto("");}}>Nový</button>
                                            
                                            {showTranslateSettings ?
                                                <NewsletterTranslateSettings 
                                                    formData = {formData}
                                                    SetFormData = {setFormData}
                                                    SetShowTranslateSettings = {SetShowTranslateSettings}
                                                />
                                            :null}

                                        </div>
                                        <div className="card-body">

                                            {error ?
                                                <Error text={error} />
                                            :
                                            <>
                                                {loading || loadingAdd ?

                                                    <Loading />  
                                                    
                                                :
                                                <>
                                                    <div className="form-group">
                                                        <label className="input-label">Naplánovat odeslání</label>
                                                        <div className="form-group">
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="isPlanned" id="isPlanned1" value="1" checked={(formData.isPlanned == 1 ?  true : false)} onChange={(e) => newsletterLib.formHandle(e,formData,setFormData)} />
                                                                <label className="form-check-label" htmlFor="isPlanned1">
                                                                    Ano
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="isPlanned" id="isPlanned2" value="0" checked={(formData.isPlanned == 0 ?  true : false)} onChange={(e) => newsletterLib.formHandle(e,formData,setFormData)} />
                                                                <label className="form-check-label" htmlFor="isPlanned2">
                                                                    Ne
                                                                </label>
                                                            </div>
                                                        </div>
                                                        
                                                    </div> 
                                                    {formData.isPlanned == 1 ?
                                                        <div className="form-group">
                                                            <label className="input-label">Datum zveřejnění</label>
                                                            <div className="form-group">
                                                                <DatePicker className="form-control" dateFormat="dd.MM.yyyy" locale="cs" selected={formData.plannedDate} onChange={date => setFormData({...formData,plannedDate: date})} />
                                                            </div>
                                                        </div> 
                                                    :null}
                                                    <div className="form-group">
                                                        <label className="input-label">Předmět emailu ({formData.selectedLang})</label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="subject" value={newsletterLib.getLangValue("subject",formData)} onChange={(e) => newsletterLib.formLangHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="input-label">Preheader ({formData.selectedLang})</label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="preheader" value={newsletterLib.getLangValue("preheader",formData)} onChange={(e) => newsletterLib.formLangHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="input-label">Hlavní fotka</label>
                                                        <div className="form-group input-group">
                                                            <div className="custom-file">
                                                                
                                                                <input
                                                                    type="file"
                                                                    className="custom-file-input"
                                                                    id="customFile"
                                                                    name="file"
                                                                    required
                                                                    multiple
                                                                    onChange={(e) => newsletterLib.openImage(e,formData,setFormData,setMainPhoto)}
                                                                />
                                                                <label className="custom-file-label" htmlFor="customFile">Vyberte fotku</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {mainPhoto ?
                                                        <div className="form-group">
                                                            <img className = "maxwidth-100" src={mainPhoto} />
                                                        </div>
                                                    : null }

                                                    <div className="form-group">
                                                        <TinyMCEEditor onChange={(content,type) => newsletterLib.formLangHandleEditor(content,"text",formData,setFormData)} initialValue={newsletterLib.getLangValue("text",formData)} />           
                                                    </div>

                                                    <div className="row">

                                                        <div className="col-12 col-sm-6">
                                                            <button style={{height:105}} onClick={() => newsletterLib.sendEmail(sendNewsletter,formData)} className="btn btn-block btn-primary">{formData.isPlanned == 1 ? "Uložit" : "Odeslat všem"}</button>
                                                        </div>
                                                        <div className="col-12 col-sm-6">
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="testEmail" value={formData.testEmail} onChange={(e) => newsletterLib.formHandle(e,formData,setFormData)} /> 
                                                            </div>
                                                            <button className="btn btn-block btn-primary" onClick={() => newsletterLib.sendEmail(sendNewsletter,formData,true)} >Odeslat testovací</button>
                                                        </div>

                                                    </div>
                                                </>
                                            }
                                            </>
                                            }

                                        </div>
                                    </div>
                                    <div className="card main ml-3" style={{flex:1}}>
                                
                                            <div className="card-header d-flex align-items-center">
                                                Seznam plánovaných newsletterů
                                                <button className="btn btn-danger btn-thiner ml-auto" onClick={() => showDelNotifi()}>Smazat označené</button>
                                            </div>
                                            <div className="card-body">
                                                {loading ?
                                                    <Loading />
                                                
                                                :
                                            
                                                    <div>
                                                        <div className="data-list">
                                                            
                                                            <div className="data-list-item header">
                                                                <div className="data-list-item-content">
                                                                    <div>Předmět</div>
                                                                    <div className="static text-center">Datum odeslání</div>
                                                                    <div className="static text-right">Možnosti</div>
                                                                </div>
                                                            </div>
                                                            <Mutation
                                                                mutation = {DELETE_NEWSLETTERS}
                                                                update = {async (cache, response) => {

                                                                    newsletterLib.updateAfterDelete(cache, response,variables);

                                                                    let notify = new Notification();
                                                                    notify.setNotification(cache,'Úspěšně smazáno',true,false);
                                                
                                                                }}
                                                                onError = {(error) => {
                                                                    const helper = new Helper(); 
                                                                    let notify = new Notification();
                                                                    notify.setNotification(null,helper.getApolloErrorText(error),false,true,props.client);
                                                                }}
                                                            >
                                                                {
                                                                    (deleteNewsletters,response) => {
                                                                
                                                                        return(
                                                                            <Fragment>
                                                                                {data && data.allPlannedNewsletters && data.allPlannedNewsletters.map((item,index) => {

                                                                                    var checked = false;
                                                                                    for(let i in dataToDelete.selectedNewsletters){
                                                                                        if(dataToDelete.selectedNewsletters[i] == item.newsletterID)checked = true;
                                                                                    }
                                                                                                                                                    
                                                                                    return (
                                                                                    
                                                                                        <div key={index} className="data-list-item-content">
                                                                                            <div>{item.subject}</div>
                                                                                            <div className="text-center static">{item.plannedDateString}</div>
                                                                                            <div className="static text-right">
                                                                                                <img onClick={() => editNewsletter(item)} className="edit-icon" src={edit} /> 
                                                                                                <input className="delete-checked" type="checkbox" name="deleteNewsletter[]" checked={checked} onChange = {(e) => deleteNewsletter(e,deleteNewsletters,item.newsletterID)} />
                                                                                            </div>
                                                                                        </div>
                                                                                                
                                                                                    )
                                                                                    
                                                                                })} 
                                                                            </Fragment>
                                                                        )
                                                                    }
                                                                }
                                                            </Mutation>
                                                        </div>

                                                        {data && data.allPlannedNewsletters && data.allPlannedNewsletters.length == 0 ?
                                                            <NoItems text={"Momentálně se zde nenachází žádné plánované newslettery."} />
                                                        :null}
                                                    
                                                    </div>

                                                }
                                        </div>
                                    </div>
                                </div>
                            )
                        }}
                    </Mutation>
                </div>
            </div>

            {showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat vybrané newslettery ?'} callback={delNewsletter} /> }

            <Modal
                className="Modal__Bootstrap modal-dialog"
                closeTimeoutMS={150}
                isOpen={showLayout}
                onRequestClose={() => setShowLayout(false)}
            >
                <div className="modal-content">
                    <div className="modal-header d-flex align-items-center">
                        <h4 className="modal-title">Šablony</h4>
                        
                        <button type="button" className="close ml-3" onClick={() => setShowLayout(false)}>                                    
                            <span aria-hidden="true">x</span>
                            <span className="sr-only">Close</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <div className="newsletter-layouts">

                            <div className="layout" onClick={() => chooseLayout(1)}>

                                <img src={sluzby} />
                                <div>Služby</div>

                            </div>
                        </div>
    
                    </div>
                </div>
            </Modal>

            <Modal
                className="Modal__Bootstrap modal-dialog"
                closeTimeoutMS={150}
                isOpen={translateLoading}
            >
                <div className="modal-content">
                    <div className="modal-body">

                    {formData.generateTranslations.enabled ?
                        <div>
                            <p className = "text-center">Překládám...</p>
                            <ProgressBar
                                progressValue = {translatedLangsCount}
                                totalValue = {data.allLanguageMutations.length}
                            />
                        </div>
                    :
                        <Loading />  
                    }
    
                    </div>
                </div>
            </Modal>

        </div>
    )

}

export default withApollo(Newsletter);