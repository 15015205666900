/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * vložení kategorie
 */
export const ADD_EDIT_CATEGORY = gql`
    mutation AddEditCategory(
        $active:Int,
        $categoryID:ID,
        $showName:Int,
        $photo:Upload, 
        $langs:[CategoryLangsInput],
        $generateTranslations:GenerateCategoryTranslationsInput
    ){
        addEditCategory(
            active:$active, 
            categoryID:$categoryID,
            langs:$langs,
            showName:$showName,
            photo:$photo,
            generateTranslations:$generateTranslations
        )
        {
            categoryID
        }
    }
`;

/**
 *  Úprava priority kategorie, když se přesouvají položky
 */
export const UPDATE_CATEGORY_PRIORITY = gql`
    mutation updateCategoryPriority($categoryID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateCategoryPriority(categoryID:$categoryID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;

/**
 *  Data pro jednu kategorii
 */

export const GET_CATEGORY = gql`
    query CategoryWithLangs($categoryID: ID!){
        categoryWithLangs(categoryID:$categoryID){
            categoryID            
            active
            showName
            photo
            langs{
                lang
                name
                niceName
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
        }
    }
`;

/**
 *  Seznam veškerých kategorií - maximlání zanoření je 7
 */
export const GET_CATEGORIES = gql`
    query AllAdminCategories($lang: String!){
        allAdminCategories(lang: $lang){
            categoryID
            name
            niceName
            showName
            active
            lang
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_CATEGORIES = gql`
    mutation deleteCategories($categoryIDs:ID!) {
        deleteCategories(categoryIDs:$categoryIDs)
        reindexCategoryPriority
    }
`;

export const GENERATE_CATEGORY_LANG = gql`
    mutation GenerateCategoryLang(
        $categoryID:ID,
        $lang:String,
        $isEdit:Boolean
        $onlyForParams:[String]
    ) {
        GenerateCategoryLang(
            categoryID:$categoryID,
            lang:$lang,
            isEdit:$isEdit
            onlyForParams:$onlyForParams
        )
    }
`;