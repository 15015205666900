/*
    Přidání kategorie
*/
import React,{ Component,Fragment } from 'react';
import {Mutation,Query} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_LINK,GET_LINKS,GENERATE_LINK_LANG} from '../Queries/index.js';
import SelectSubLink from './SelectSubLink';
import { withApollo } from 'react-apollo';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import LinkLib from '../Library/link';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import TinyMCEBasic from '../../TinyMCEFileManager/Components/TinyMCEBasic';
import ProgressBar from '../../../GlobalComponents/ProgressBar.js';
import LinkTranslateSettings from './LinkTranslateSettings.js';
import settingsIcon from '../../../Public/Images/settings.svg';

const INITIAL_STATE = {
    err:"",
    allLanguageMutations:[],
    metaImage:null,
    allMemberships:[],
    allServices:[],
    selectedTab:1,
    allLinks:[],
    addLoading:false,
    showTranslateSettings:false,
    translatedLangsCount:0,
    formData:{
        generateTranslations:{
            enabled:true,
            onlyForParams:[],
            onlyFor:""
        },
        selectedLang:"cz",
        active:1,
        parentID:"0",
        themeID:1,
        inHeader:1,
        inFooter:0,
        linkID:0,
        isBlog:0,
        hideMenu:0,
        thanksLinkID:0,
        metaImage:null,
        membershipPercentageDiscountDateFrom:null,
        membershipPercentageDiscountDateTo:null,
        langs:[],
        selectedMemberships:[],
        selectedUpSellBomb:[]
    }
}

class AddEditLink extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
        
        this.linkLib = new LinkLib(this); 
    }

    componentDidMount = () => {
        
        this.linkLib.getData();
        
        if(this.props.selectedLinkID && this.props.selectedLinkID != 0)
            this.linkLib.SetFormData("generateTranslations",{...this.state.formData.generateTranslations,enabled:false});
    }

    showTab(e,tab){

        e.preventDefault();

        this.setState({
            selectedTab:tab
        }); 
    }

    render(){

        var {err,allLanguageMutations,selectedTab} = this.state;

        return(

            <Mutation 
                mutation={ADD_EDIT_LINK}
                errorPolicy = "all"
                refetchQueries = {(data)=>{
                    return [{
                        query:GET_LINKS,
                        variables:{lang:"cz"}
                    }]
                }}
                update = {async (cache, response) => {

                    var success = true;

                    if(this.state.formData.generateTranslations.enabled)
                    {
                        this.setState({translatedLangsCount:3,addLoading:true});
                        for(let val of allLanguageMutations)
                        {
                            if(val.suffix != "cz" && val.suffix != "en" && val.suffix != "us")
                            {
                                try
                                {
                                    await this.props.client.mutate({
                                        mutation:GENERATE_LINK_LANG,
                                        variables:{
                                            lang: val.suffix,
                                            linkID: response.data.addEditLink.linkID,
                                            isEdit: (this.props.selectedLinkID && this.props.selectedLinkID != 0 ? true : false),
                                            onlyForParams:this.state.formData.generateTranslations.onlyForParams
                                        }
                                    });

                                    this.setState({translatedLangsCount:this.state.translatedLangsCount + 1});

                                }
                                catch(err)
                                {
                                    const helper = new Helper(); 
                                    this.setState({
                                        err:helper.getApolloErrorText(err)
                                    });
                                    success = false;
                                    break;
                                }
                            }
                        }

                        this.setState({addLoading:false});
                    }

                    if(success)
                    {
                        let notify = new Notification();

                        if(this.props.selectedLinkID && this.props.selectedLinkID != 0) 
                            notify.setNotification(cache,'Úspěšně uloženo',true,false);
                        else 
                            notify.setNotification(cache,'Úspěšně přidáno',true,false);

                        this.props.openCloseModal(false);
                    }

                }}                
            >
            {
                (addEditLink,{loading, error}) => {

                    const {addLoading,metaImage,allMemberships,allServices,allLinks,showTranslateSettings} = this.state;
                    const {generateTranslations,selectedMemberships,selectedUpSellBomb,selectedLang,parentID,themeID,active,inHeader,inFooter,isBlog,hideMenu,membershipPercentageDiscountDateFrom,membershipPercentageDiscountDateTo,thanksLinkID} = this.state.formData;
                    const {selectedLinkID} = this.props;

                    if(error){
                        const helper = new Helper(); 
                        err = helper.getApolloErrorText(error);
                    }

                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => !loading && !addLoading && this.props.openCloseModal(false)}
                        >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{(selectedLinkID && selectedLinkID != 0 ? "Úprava odkazu" : "Přidání odkazu")}</h4>
                                    {!loading && !addLoading ? 
                                        <> 
                                            <div className="select-lang">

                                                <div className={"form-check generate-all-langs" + (!generateTranslations.enabled ? " bigger" : "")}>
                                                    <input 
                                                        className="form-check-input" 
                                                        type="checkbox" 
                                                        name="generateTranslations" 
                                                        id={"generateTranslations"} 
                                                        checked={generateTranslations.enabled} 
                                                        onChange={(e) => {
                                                            this.linkLib.SetFormData("generateTranslations",{...generateTranslations,enabled:e.target.checked},() => {
                                                                this.linkLib.SetFormData("selectedLang","cz");
                                                            })
                                                        }} 
                                                    />
                                                    <label className="form-check-label" htmlFor={"generateTranslations"}>
                                                        Vygenerovat všechny překlady
                                                    </label>
                                                    
                                                </div>

                                                {generateTranslations.enabled && selectedLinkID && selectedLinkID != 0 ?
                                                    <img 
                                                        className="cursor-pointer"
                                                        style={{height:"25px",marginLeft:"10px"}} 
                                                        src={settingsIcon} 
                                                        onClick={() => this.setState({showTranslateSettings:!showTranslateSettings})}
                                                    />
                                                :null}
                                                
                                                {!generateTranslations.enabled ?
                                                    <select 
                                                        className="form-control" 
                                                        name="selectedLang" 
                                                        onChange={(e) => this.linkLib.formHandle(e)}
                                                    >
                                                        {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                                            <option key={index} value={item.suffix}> {item.suffix} </option>
                                                        ))}
                                                    </select>
                                                :null}

                                            </div>
                                            <button type="button" className="close ml-3" onClick={() => this.props.openCloseModal(false)}>                                    
                                                <span aria-hidden="true">x</span>
                                                <span className="sr-only">Close</span>
                                            </button>
                                        </>
                                    :null}

                                    {showTranslateSettings ?
                                        <LinkTranslateSettings 
                                            generateTranslations = {generateTranslations}
                                            parent = {this}
                                        />
                                    :null}
                                </div>
                                <div className="modal-body">

                                    {err ?
                                        <Error text={err} />
                                    :
                                        <Fragment>
                                            {!loading && !addLoading ?

                                                <>
                                                    <ul className="nav nav-tabs" role="tablist">
                                                        <li className="nav-item">
                                                            <a className={(selectedTab == 1 ? "nav-link active" : "nav-link")} href="#main" onClick={(e) => this.showTab(e,1)}>Základní údaje</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className={(selectedTab == 2 ? "nav-link active" : 'nav-link')} href="#order" onClick={(e) => {e.preventDefault(); this.showTab(e,2)}}>Objednávka kurzu / členství</a>
                                                        </li>

                                                    </ul> 

                                                    {selectedTab == 1 ?
                                                        <div className="row">

                                                            <div className="col-12 col-sm-3">
                                                                <label className="input-label">Aktivní</label>
                                                                <div className="form-group">
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" name="active" id="active1" value="1" checked={(active == 1 ?  true : false)} onChange={(e) => this.linkLib.formHandle(e)} />
                                                                        <label className="form-check-label" htmlFor="active1">
                                                                            Ano
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" name="active" id="active2" value="0" checked={(active == 0 ?  true : false)} onChange={(e) => this.linkLib.formHandle(e)} />
                                                                        <label className="form-check-label" htmlFor="active2">
                                                                            Ne
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-3">
                                                                <label className="input-label">Zobrazit v hlavičce</label>
                                                                <div className="form-group">
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" name="inHeader" id="inHeader1" value="1" checked={(inHeader == 1 ?  true : false)} onChange={(e) => this.linkLib.formHandle(e)} />
                                                                        <label className="form-check-label" htmlFor="inHeader1">
                                                                            Ano
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" name="inHeader" id="inHeader2" value="0" checked={(inHeader == 0 ?  true : false)} onChange={(e) => this.linkLib.formHandle(e)} />
                                                                        <label className="form-check-label" htmlFor="inHeader2">
                                                                            Ne
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-3">
                                                                <label className="input-label">Zobrazit v patičce</label>
                                                                <div className="form-group">
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" name="inFooter" id="inFooter1" value="1" checked={(inFooter == 1 ?  true : false)} onChange={(e) => this.linkLib.formHandle(e)} />
                                                                        <label className="form-check-label" htmlFor="inFooter1">
                                                                            Ano
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" name="inFooter" id="inFooter2" value="0" checked={(inFooter == 0 ?  true : false)} onChange={(e) => this.linkLib.formHandle(e)} />
                                                                        <label className="form-check-label" htmlFor="inFooter2">
                                                                            Ne
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>   
                                                            <div className="col-12 col-sm-3">
                                                                <label className="input-label">Zobrazit články (blog,produkty,...)</label>
                                                                <div className="form-group">
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" name="isBlog" id="isBlog1" value="1" checked={(isBlog == 1 ?  true : false)} onChange={(e) => this.linkLib.formHandle(e)} />
                                                                        <label className="form-check-label" htmlFor="isBlog1">
                                                                            Ano
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" name="isBlog" id="isBlog2" value="0" checked={(isBlog == 0 ?  true : false)} onChange={(e) => this.linkLib.formHandle(e)} />
                                                                        <label className="form-check-label" htmlFor="isBlog2">
                                                                            Ne
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>  
                                                            <div className="col-12">
                                                                <label className="input-label">Schovat menu</label>
                                                                <div className="form-group">
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" name="hideMenu" id="hideMenu1" value="1" checked={(hideMenu == 1 ?  true : false)} onChange={(e) => this.linkLib.formHandle(e)} />
                                                                        <label className="form-check-label" htmlFor="hideMenu1">
                                                                            Ano
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio" name="hideMenu" id="hideMenu2" value="0" checked={(hideMenu == 0 ?  true : false)} onChange={(e) => this.linkLib.formHandle(e)} />
                                                                        <label className="form-check-label" htmlFor="hideMenu2">
                                                                            Ne
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>                                                                                                                     
                                                            <div className="col-12 col-sm-4">
                                                                <label className="input-label">Název odkazu ({selectedLang})</label>
                                                                <div className="form-group">
                                                                    <input className="form-control" type="text" name="name" value={this.linkLib.getLangValue("name")} onChange={(e) => this.linkLib.formLangHandle(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-4">
                                                                <label className="input-label">Nadřazený odkaz</label>
                                                                <div className="form-group">
                                                                    
                                                                    <Query query={GET_LINKS} errorPolicy="all" variables={{lang:"cz"}}>
                                                                        {
                                                                            ({data,loading,error}) => {

                                                                                if(loading) return (<Loading />);
                                                                                if(error){
                                                                                    const helper = new Helper(); 
                                                                                    return (<Error text={helper.getApolloErrorText(error)} />);
                                                                                }

                                                                                return(
                                                                                    <select value={parentID} className="form-control" name="parentID" onChange={(e) => this.linkLib.formHandle(e)}>

                                                                                        <option key={0} value={0}> hlavní odkaz</option>

                                                                                        <SelectSubLink links={data.allLinks} />
                                                                                        
                                                                                    </select>
                                                                                );
                                                                            }
                                                                        }
                                                                    </Query>

                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-4">
                                                                <label className="input-label">Barevný motiv</label>
                                                                <div className="form-group">
                                                                    
                                                                    
                                                                    <select value={themeID} className="form-control" name="themeID" onChange={(e) => this.linkLib.formHandle(e)}>

                                                                        <option key={0} value={1}> černý</option>
                                                                        <option key={1} value={2}> bílý</option>
                                                                        
                                                                        
                                                                    </select>
                                                                                

                                                                </div>
                                                            </div>

                                                            <div className="col-12">
                                                                <label className="input-label">Vlastní URL adresa ({selectedLang})</label>
                                                                <div className="form-group">
                                                                    <input className="form-control" type="text" name="ownUrl" value={this.linkLib.getLangValue("ownUrl")} onChange={(e) => this.linkLib.formLangHandle(e)} />
                                                                </div>
                                                            </div>


                                                            <div className="col-12">
                                                                <div className="card main">

                                                                    <div className="card-header d-flex align-items-center">
                                                                        SEO
                                                                    </div>

                                                                    <div className="card-body">

                                                                        <div className="row">
                                                                            <div className="col-12 col-sm-6">
                                                                                <label className="input-label">Meta nadpis ({selectedLang})</label>
                                                                                <div className="form-group">
                                                                                    <input className="form-control" type="text" name="metaTitle" value={this.linkLib.getLangValue("metaTitle")} onChange={(e) => this.linkLib.formLangHandle(e)} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-12 col-sm-6">
                                                                                <label className="input-label">Meta klíčová slova ({selectedLang})</label>
                                                                                <div className="form-group">
                                                                                    <input className="form-control" type="text" name="metaKeywords" value={this.linkLib.getLangValue("metaKeywords")} onChange={(e) => this.linkLib.formLangHandle(e)} />
                                                                                </div>
                                                                            </div>
                                                                            
                                                                            <div className="col-12">
                                                                                <label className="input-label">Meta popis ({selectedLang})</label>
                                                                                <div className="form-group input-group">
                                                                                    <input className="form-control" type="text" name="metaDescription" value={this.linkLib.getLangValue("metaDescription")} onChange={(e) => this.linkLib.formLangHandle(e)} />
                                                                                    
                                                                                    <div className="input-group-append">
                                                                                        <span className={"input-group-text " + (this.linkLib.getCharCount("metaDescription") > 160 ? 'red' : '')}>{this.linkLib.getCharCount("metaDescription")}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <label className="input-label">Meta obrázek</label>
                                                                                <div className="input-group">
                                                                                    <div className="custom-file">
                                                                                        
                                                                                        <input
                                                                                            type="file"
                                                                                            className="custom-file-input"
                                                                                            id="customFile"
                                                                                            required
                                                                                            onChange={(e) => this.linkLib.openMetaImage(e)}
                                                                                        />
                                                                                        <label className="custom-file-label" htmlFor="customFile">Vyberte fotku</label>
                                                                                    </div>
                                                                                </div>
                                                                            
                                                                                {metaImage ?
                                                                                    <div>
                                                                                        <img style={{width:"100%"}} src={metaImage} />
                                                                                    </div>  
                                                                                :null}      
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    :null}

                                                    {selectedTab == 2 ?
                                                        
                                                        <div>
                                                            <div className="col-12">

                                                                <div className="form-group">

                                                                    <label className="input-label">Nastavit odpočet pro slevy na této stránce</label>
                                                                    <div className="row">
                                                                        <div className="col-12 col-sm-4">
                                                                            <div className="fomr-group">
                                                                                <label className="input-label">Sleva od</label>
                                                                                <DatePicker showTimeSelect className="form-control" dateFormat="dd.MM.yyyy HH:mm" locale="cs" selected={membershipPercentageDiscountDateFrom} onChange={date => this.linkLib.SetFormData("membershipPercentageDiscountDateFrom",date)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-4">
                                                                            <div className="fomr-group">
                                                                                <label className="input-label">Sleva do</label>
                                                                                <DatePicker showTimeSelect className="form-control" dateFormat="dd.MM.yyyy HH:mm" locale="cs" selected={membershipPercentageDiscountDateTo} onChange={date => this.linkLib.SetFormData("membershipPercentageDiscountDateTo",date)} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="card form-group">

                                                                    <div className="card-header">
                                                                        Který kurz / členství prodávat na tomto odkaze ?
                                                                    </div>
                                                                    <div className="card-body">

                                                                        <div className="row">
                                                                            {allMemberships.map((item,index) => {

                                                                                var check = false;
                                                                                var membership = null;
                                                                                for(let val of selectedMemberships)
                                                                                {
                                                                                    if(val.membershipDurationID == item.membershipDurationID)
                                                                                    {
                                                                                        check = true;
                                                                                        membership = val;
                                                                                    }
                                                                                }

                                                                                return(
                                                                                    <div key={item.membershipDurationID} className="col-12 col-sm-6 col-md-3">
                                                                                        <div className="card">
                                                                                            <div className="card-header">
                                                                                                <div className="form-check">
                                                                                                    <input className="form-check-input" type="checkbox" name="membership" id={"membership"+index} value={item.membershipDurationID} checked={check} onChange={(e) => this.linkLib.SelectMembership(item.membershipDurationID)} />
                                                                                                    <label className="form-check-label" htmlFor={"membership"+index}>
                                                                                                        {item.name}
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="card-body">
                                                                                                
                                                                                                <div className="fomr-group">
                                                                                                    <label className="input-label">Sleva %</label>
                                                                                                    <input className="form-control" type="number" value={(membership ? membership.percentageDiscount : 0)} onChange={(e) => this.linkLib.SetMembershipDiscountData(e.target.value,item.membershipDurationID,"percentageDiscount")} />
                                                                                                </div>                                                                                    
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                            
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <label className="input-label">Děkovací stránka</label>
                                                                    <select className="form-control" name="thanksLinkID" value={thanksLinkID} onChange={(e) => this.linkLib.formHandle(e)}>
                                                                        <option value={0} key={-1}>--Nepoužívat děkovací stránku--</option>
                                                                        {allLinks.map((item,ind) => {
                                                                            
                                                                            return(
                                                                                <>
                                                                                    <option value={item.linkID} key={item.linkID}>{item.name}</option>
                                                                                    {item.subLink ?
                                                                                        (item.subLink.map((subItem,index) => {
                                                                                            
                                                                                            return(
                                                                                                <option value={subItem.linkID} key={subItem.linkID}>├-- {subItem.name}</option>
                                                                                            )
                                                                                        }))
                                                                                    :null}
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="col-12">

                                                                <label className="input-label">Upsell bomba</label>
                                                                <div className="card form-group">

                                                                    <div className="card-header">
                                                                        Upsell bomba - co v ní prodávat
                                                                    </div>
                                                                    <div className="card-body">

                                                                        <div className="row">

                                                                            <div className="col-12">
                                                                                <label className="input-label">Nadpis v objednávce ({selectedLang})</label>
                                                                                <div className="form-group">
                                                                                    <input className="form-control" type="text" name="upSellBombTitle" value={this.linkLib.getLangValue("upSellBombTitle")} onChange={(e) => this.linkLib.formLangHandle(e)} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-12 col-sm-6">
                                                                                <label className="input-label">Kurzy</label>
                                                                                {allMemberships.map((item,index) => {

                                                                                    var check = false;
                                                                                    for(let val of selectedUpSellBomb)
                                                                                    {
                                                                                        if(val.valueID == item.membershipDurationID && val.upSellBombTypeID == 1)
                                                                                            check = true;
                                                                                    }

                                                                                    return(
                                                                                        <div key={item.membershipDurationID} >
                                                                                           
                                                                                            <div className="form-check">
                                                                                                <input className="form-check-input" type="checkbox" name="upSellMembership" id={"upSellMembership"+index} value={item.membershipDurationID} checked={check} onChange={(e) => this.linkLib.SelectUpSellItem(item.membershipDurationID,1)} />
                                                                                                <label className="form-check-label" htmlFor={"upSellMembership"+index}>
                                                                                                    {item.name}
                                                                                                </label>
                                                                                            </div>
                                                                                                
                                                                                            
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>

                                                                            <div className="col-12 col-sm-6">
                                                                                <label className="input-label">Služby</label>
                                                                                {allServices.map((item,index) => {

                                                                                    var check = false;
                                                                                    for(let val of selectedUpSellBomb)
                                                                                    {
                                                                                        if(val.valueID == item.serviceID && val.upSellBombTypeID == 2)
                                                                                        {
                                                                                            check = true;
                                                                                        }
                                                                                    }

                                                                                    return(
                                                                                        <div key={item.serviceID} >
                                                                                           
                                                                                            <div className="form-check">
                                                                                                <input className="form-check-input" type="checkbox" name="upSellService" id={"upSellService"+index} value={item.serviceID} checked={check} onChange={(e) => this.linkLib.SelectUpSellItem(item.serviceID,2)} />
                                                                                                <label className="form-check-label" htmlFor={"upSellService"+index}>
                                                                                                    {item.name}
                                                                                                </label>
                                                                                            </div>
                                                                                                
                                                                                            
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                            
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <label className="input-label">Text emailu po nákupu ({selectedLang})</label>
                                                                    <div className="form-group">
                                                                        <TinyMCEBasic 
                                                                            name="perex"
                                                                            value={this.linkLib.getLangValue("membershipAfterBuyEmailText")}
                                                                            OnEditorChange={(content) => this.linkLib.formLangHandleEditor(content,"membershipAfterBuyEmailText")}
                                                                        />
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <label className="input-label">Horní text vyskakovacího okna při nákupu členství ({selectedLang})</label>
                                                                    <div className="form-group">
                                                                        <TinyMCEBasic 
                                                                            name="perex"
                                                                            value={this.linkLib.getLangValue("membershipPopUpTextUp")}
                                                                            OnEditorChange={(content) => this.linkLib.formLangHandleEditor(content,"membershipPopUpTextUp")}
                                                                        />
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <label className="input-label">Spodní text vyskakovacího okna při nákupu členství ({selectedLang})</label>
                                                                    <div className="form-group">
                                                                        <TinyMCEBasic 
                                                                            name="perex"
                                                                            value={this.linkLib.getLangValue("membershipPopUpTextDown")}
                                                                            OnEditorChange={(content) => this.linkLib.formLangHandleEditor(content,"membershipPopUpTextDown")}
                                                                        />
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            

                                                        </div>
                                                    :null}

                                                </>

                                            :
                                                <div>
                                                    {generateTranslations.enabled ?
                                                        <div>
                                                            <p className = "text-center">Překládám...</p>
                                                            <ProgressBar
                                                                progressValue = {this.state.translatedLangsCount}
                                                                totalValue = {allLanguageMutations.length}
                                                            />
                                                        </div>
                                                    :
                                                        <Loading />  
                                                    }
                                                </div>

                                                

                                            }  
                                        </Fragment>
                                    }
                                            
                                </div>
                                <div className="modal-footer"> 
                                    {!loading && !addLoading ? 
                                        <>
                                            <button type="button" className="btn btn-primary flex-fill" onClick={() => this.linkLib.addLink(addEditLink)}>{(this.props.selectedLinkID && this.props.selectedLinkID != 0 ? "Upavit" : "Přidat")}</button>
                                            <button type="button" className="btn btn-danger" onClick={() => this.props.openCloseModal(false)}>Zavřít</button>
                                        </>
                                    :null}
                                </div>
                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(AddEditLink);