/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

import Notification from '../../../Library/notification';
import {GET_CATEGORIES, GET_CATEGORY} from '../Queries/index.js';
import { SERVER_URL,CATEGORY_IMAGE_PATH } from '../../../Config';

class Category {

    constructor(parent){
        this.parent = parent;
    }

    /**
     * Přidání nového stavu objednávky
     * @param {*} addStatus funkce z apollo, pomocí které se posílají data na server
     */

    addCategory(addCategory){

        const data = this.parent.state.formData;
        data.showName = parseInt(data.showName);
      
        let notify = new Notification();   

        if((data.file != "" && !this.parent.props.selectedCategoryID) || (this.parent.props.selectedCategoryID && this.parent.props.selectedCategoryID != 0)){ 
            
            if(data.categoryID != 0){
                addCategory({
                    variables:data
                }); 
            }else{
                notify.setNotification(null,'Nevybrali jste kategorii.',false,true,this.parent.props.client);
            }
                                
        }else{
            notify.setNotification(null,'Nevybrali jste fotku.',false,true,this.parent.props.client);
        }

    } 


    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDeleteCategory(cache, response){

        var resp = response.data.deleteCategories.split(",");

        const { allAdminCategories } = cache.readQuery({ query: GET_CATEGORIES ,variables:{lang:'cz'} });
        var arr = [...allAdminCategories];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.categoryID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_CATEGORIES,
            variables:{lang:'cz'},
            data:{
                allAdminCategories: [...arr]
            } 
        });

    }
    
    /**
     * při inicializaci přidání kategorie nebo její úpravy vytáhne potřebná data k dopravě
     */

    async getData(){

        var categoryID = 0;
        if(this.parent.props.selectedCategoryID)categoryID = this.parent.props.selectedCategoryID;
        
        var data = await this.parent.props.client.query({ 
            query: GET_CATEGORY,
            errorPolicy:"all",
            variables:{categoryID:categoryID}, 
            fetchPolicy: 'network-only'
        });
        
        
        if(data.errors){
            this.parent.setState({apolloError:data.errors[0].message});
        }
        
        if(data.data){                  
            if(data.data.categoryWithLangs){

                const langs = this.completeLangsArray(data.data.categoryWithLangs.langs,data.data.allLanguageMutations);

                var mainPhoto = "";
                if(data.data.categoryWithLangs.photo) mainPhoto = SERVER_URL + "/" + CATEGORY_IMAGE_PATH + "/stredni_" + data.data.categoryWithLangs.photo;

                this.parent.setState({
                    apolloError:"",
                    selectedcategoryID:categoryID,
                    mainPhoto,
                    allLanguageMutations: data.data.allLanguageMutations,
                    formData:{...this.parent.state.formData,
                        categoryID:     data.data.categoryWithLangs.categoryID,
                        active:         data.data.categoryWithLangs.active,
                        showName:       data.data.categoryWithLangs.showName,
                        langs
                    }
                });
            }else{
                this.parent.setState({ apolloError:""});
            }
        }

        
        //kdyz pridavame zaznam
        if(categoryID == 0){
            let langs = this.completeLangsArray([],data.data.allLanguageMutations); 

            this.parent.setState({
                allLanguageMutations: data.data.allLanguageMutations,
                formData:{...this.parent.state.formData,
                    langs:langs
                }
            });
        }

    }

    completeLangsArray = (langsFromDb,allActiveLanguages) => {
        let langs = langsFromDb;
        for(const l of allActiveLanguages){
            let langSet = false;
            for(const lang of langs){
                if(l.suffix == lang.lang){
                    langSet = true;
                    break;
                }
            }

            if(!langSet){
                langs.push({
                    name:"",
                    lang:l.suffix
                });
            }
        }
        return langs;
    }

    SetFormData(type,value,CallBack){

        this.parent.setState({formData:{...this.parent.state.formData,[type]:value}},() => {
            if(CallBack)
                CallBack()
        });
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formHandle(e){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({formData:{...this.parent.state.formData,[n]: v}});
              
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangHandle(e){
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        let langs       = this.parent.state.formData.langs;

        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[n] = v;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
         
    }

    /**
     * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
     * @param {String} propertyName - název proměnné v objektu lang
     * 
     * @returns property value
     */
    getLangValue = (propertyName) => {
        for(const lang of this.parent.state.formData.langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                return lang[propertyName];
            }
        }
        return "";
    }
    
    /**
     * Otevře obrázke, zpracuje a zobrazí jeho náhled
     * @param {*} e 
     */

    openImage(e){
        var photo = e.target.files[0];        
        var reader = new FileReader();
        reader.onload = () => {
            var dataURL = reader.result;
            this.parent.setState({
                mainPhoto:dataURL,
                formData:{...this.parent.state.formData,photo:photo}
            })
        };
        reader.readAsDataURL(photo);
    }

}

export default Category;