/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

import Notification from '../../../Library/notification';
import {GET_AUDIOS, GET_AUDIO} from '../Queries/index.js';

class Audio {

    constructor(parent){
        this.parent = parent;
    }

    /**
     * Přidání/Editace videa
     * @param {*} addAudio funkce z apollo, pomocí které se posílají data na server
     */

    addAudio = (addAudio) => {

        const data = this.parent.state.formData;
        let notify = new Notification();   

        if(data.membershipDurationID != 0){ 

            /*
            var err = "";
            for(const lang of data.langs){
                if(!lang.file)
                {
                    if(err)err += ", ";
                    err += lang.lang;
                } 
            }

            if(err == "" || data.audioID != 0){*/
                                 
                let langs = [];
                for(const lang of data.langs){
                    langs.push({
                        name: lang.name,
                        audioText: lang.audioText,
                        file: lang.file,
                        text: lang.text,
                        videoUrl:lang.videoUrl,
                        lang:lang.lang,
                        duration:lang.duration,
                        fileSize:parseInt(lang.fileSize)
                    });
                }

                addAudio({
                    variables:{
                        audioID:data.audioID,
                        categoryID:data.categoryID,
                        membershipDurationID:data.membershipDurationID,
                        audioTypeID:data.audioTypeID,
                        epizodNumber:data.epizodNumber,
                        langs,
                        generateTranslations:data.generateTranslations
                    }
                });
/*
            }else{
                notify.setNotification(null,'Je potřeba vložit nahrávku ještě pro tyto jazyky: ' + err,false,true,this.parent.props.client);
            }*/
        
        }else{
            notify.setNotification(null,'Nevybrali jste kurz / členství.',false,true,this.parent.props.client);
        }
    } 


    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response){

        var resp = response.data.deleteAudios.split(",");

        const { allAudios } = cache.readQuery({ query: GET_AUDIOS ,variables:{lang:'cz'}});
        var arr = [...allAudios];
        
        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.audioID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_AUDIOS,
            variables:{lang:'cz'},
            data:{
                allAudios: [...arr]
            } 
        });

    }

    CopyVimeoIDToAllLangs(url)
    {
        var langs = [...this.parent.state.formData.langs];

        console.log(langs);

        for(let i in langs)
        {
            langs[i] = {...langs[i],videoUrl:url}
        }

        this.SetFormData("langs",langs);
    }

    SetFormData(type,value,CallBack){

        this.parent.setState({formData:{...this.parent.state.formData,[type]:value}},() => {
            if(CallBack)
                CallBack()
        });
    }
    
    /**
     * při inicializaci přidání kategorie nebo její úpravy vytáhne potřebná data k dopravě
     */

    async getData(){

        var audioID = 0;
        if(this.parent.props.selectedAudioID)audioID = this.parent.props.selectedAudioID;
        
        var data = await this.parent.props.client.query({ 
            query: GET_AUDIO,
            errorPolicy:"all",
            variables:{audioID:audioID}, 
            fetchPolicy: 'network-only'
        });
        
        
        if(data.errors){
            this.parent.setState({apolloError:data.errors[0].message});
        }
        
        //kdyz upravujeme zaznam
        if(data.data)
        {               
            if(data.data.audioWithLangs){
                
                let langs = this.completeLangsArray(data.data.audioWithLangs.langs,data.data.allLanguageMutations);                
                
                var audioFiles = {};
                for(let i = 0; i < data.data.audioWithLangs.langs.length; i++)
                {
                    if(data.data.audioWithLangs.langs[i].file)
                        audioFiles[data.data.audioWithLangs.langs[i].lang] = data.data.audioWithLangs.langs[i].file;
                    else 
                        audioFiles[data.data.audioWithLangs.langs[i].lang] = null;   
                }

                console.log(data);

                this.parent.setState({
                    apolloError:"",
                    selectedAudioID:audioID,
                    selectedAudioHash:data.data.audioWithLangs.hash,
                    audioFiles:audioFiles,
                    allLanguageMutations: data.data.allLanguageMutations,
                    allAudioTypes: data.data.allAudioTypes,
                    allCategories:data.data.allAdminCategories,
                    allAdminMembershipDurations:data.data.allAdminMembershipDurations,
                    formData:{...this.parent.state.formData,
                        audioID:                data.data.audioWithLangs.audioID,
                        categoryID:             data.data.audioWithLangs.categoryID,
                        membershipDurationID:   data.data.audioWithLangs.membershipDurationID,
                        audioTypeID:            data.data.audioWithLangs.audioTypeID,
                        epizodNumber:           data.data.audioWithLangs.epizodNumber,
                        langs:langs
                    }
                });
            }else{
                this.parent.setState({ apolloError:""});
            }
        }

        //kdyz pridavame zaznam
        if(audioID == 0)
        {
            let langs = this.completeLangsArray([],data.data.allLanguageMutations);            
            langs = JSON.parse(JSON.stringify(langs));

            this.parent.setState({
                allLanguageMutations: data.data.allLanguageMutations,
                allCategories:data.data.allAdminCategories,
                allAudioTypes: data.data.allAudioTypes,
                allAdminMembershipDurations:data.data.allAdminMembershipDurations,
                formData:{...this.parent.state.formData,
                    langs:langs
                }
            });
        }

    }

    completeLangsArray = (langsFromDb,allActiveLanguages) => {
        let langs = [...langsFromDb];
        for(const l of allActiveLanguages){
            let langSet = false;
            for(let i = 0; i < langs.length;i++)
            {
                if(l.suffix == langs[i].lang)
                {
                    langs[i] = {...langs[i],file : null};
                    langSet = true;
                    break;
                }
            }

            if(!langSet){
                langs.push({
                    name:"",
                    audioText:"",
                    duration: "",
					fileSize: 0,
                    file:null,
                    text:"",
                    videoUrl:"",
                    lang:l.suffix
                });
            }
        }
        return langs;
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formHandle(e){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({formData:{...this.parent.state.formData,[n]: v}});
              
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangHandle(e){
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        let langs       = this.parent.state.formData.langs;

        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[n] = v;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
         
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře přes tinyMCE editor
     * @param {String} data data tinyMCE editoru
     * @param {String} paramName nazev parametru, kteremu nastavujeme textovou hodnotu
     */
    
    formLangHandleEditor(content,paramName){
        
        let langs       = this.parent.state.formData.langs;

        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[paramName] = content;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
    }

    /**
     * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
     * @param {String} propertyName - název proměnné v objektu lang
     * 
     * @returns property value
     */
    getLangValue = (propertyName,l) => {
        for(const lang of this.parent.state.formData.langs){
            if(lang.lang == (l ? l : this.parent.state.formData.selectedLang))
                return lang[propertyName];
        }
        return "";
    }
    

    

}

export default Audio;