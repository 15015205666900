/*
    Přidání kategorie
*/
import React,{ Component,Fragment } from 'react';
import {Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_MEMBERSHIP,GENERATE_MEMBERSHIP_LANG} from '../Queries/index.js';
import { withApollo } from 'react-apollo';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import MembershipLib from '../Library/membership';
import TinyMCEBasic from '../../TinyMCEFileManager/Components/TinyMCEBasic';
import settingsIcon from '../../../Public/Images/settings.svg';
import MembershipTranslateSettings from './MembershipTranslateSettings';
import ProgressBar from '../../../GlobalComponents/ProgressBar';

const INITIAL_STATE = {
    err:"",
    addLoading:false,
    showTranslateSettings:false,
    translatedLangsCount:0,
    formData:{
        generateTranslations:{
            enabled:true,
            onlyForParams:[],
            onlyFor:""
        },
        membershipDurationID:0,
        thanksLinkID:0,
        length:0,
        autoPaymentsNumber:0,
        showName:1,
        active:1,
        hidden:0,
        freeAfterReg:0,
        langs:[],
        selectedLang:'cz',
        photo:null
    },
    allLinks:[],
    mainPhoto:"",
    allLanguageMutations:[]
}

class AddEditMembership extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
        
        this.membershipLib = new MembershipLib(this); 
    }

    componentDidMount(){
        this.membershipLib.getData();

        if(this.props.selectedMembershipID && this.props.selectedMembershipID != 0)
            this.membershipLib.SetFormData("generateTranslations",{...this.state.formData.generateTranslations,enabled:false});
    }    

    render(){

        var {
            err,
            addLoading,
            showTranslateSettings,
            allLanguageMutations
        } = this.state;
        var {selectedMembershipID,listVariables} = this.props;

        return(

            <Mutation 
                mutation={ADD_EDIT_MEMBERSHIP}
                errorPolicy = "all"
                update = {async (cache, response) => {

                    var success = true;

                    if(this.state.formData.generateTranslations.enabled)
                    {
                        this.setState({translatedLangsCount:1,addLoading:true});

                        for(let val of allLanguageMutations)
                        {
                            if(val.suffix != "cz" && val.suffix != "us")
                            {
                                try
                                {
                                    await this.props.client.mutate({
                                        mutation:GENERATE_MEMBERSHIP_LANG,
                                        variables:{
                                            lang: val.suffix,
                                            membershipDurationID: response.data.addEditMembership.membershipDurationID,
                                            isEdit: (this.props.selectedMembershipID && this.props.selectedMembershipID != 0 ? true : false),
                                            onlyForParams:this.state.formData.generateTranslations.onlyForParams
                                        }
                                    });

                                    this.setState({translatedLangsCount:this.state.translatedLangsCount + (val.suffix == "en" ? 2 : 1)});

                                }
                                catch(err)
                                {
                                    const helper = new Helper(); 
                                    this.setState({
                                        err:helper.getApolloErrorText(err)
                                    });
                                    success = false;
                                    break;
                                }
                            }
                        }

                        this.setState({addLoading:false});
                    }

                    if(success)
                    {

                        this.props.refetch();

                        let notify = new Notification();

                        if(selectedMembershipID && selectedMembershipID != 0) notify.setNotification(cache,'Úspěšně uloženo',true,false);
                        else notify.setNotification(cache,'Úspěšně přidáno',true,false);

                        this.props.openCloseModal(null);

                    }

                }}                
            >
            {
                (addEditMembership,{loading, error}) => {

                    const {
                        selectedLang,
                        hidden,
                        freeAfterReg,
                        thanksLinkID,
                        showName,
                        active,
                        generateTranslations
                    } = this.state.formData;
                    const {allLanguageMutations,allLinks,mainPhoto} = this.state;
                    
                    if(error){
                        const helper = new Helper(); 
                        err = helper.getApolloErrorText(error);
                    }

                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => !loading && !addLoading && this.props.openCloseModal(null)}
                        >
                            <div className="modal-content">
                                <div className="modal-header d-flex align-items-center">
                                    <h4 className="modal-title">{(selectedMembershipID && selectedMembershipID != 0 ? "Úprava členství / produktu" : "Přidání členství / produktu")}</h4>
                                    
                                    {!loading && !addLoading ? 
                                        <>
                                            <div className="select-lang">
                                                <div className={"form-check generate-all-langs" + (!generateTranslations.enabled ? " bigger" : "")}>
                                                    <input 
                                                        className="form-check-input" 
                                                        type="checkbox" 
                                                        name="generateTranslations" 
                                                        id={"generateTranslations"} 
                                                        checked={generateTranslations.enabled} 
                                                        onChange={(e) => {
                                                            this.membershipLib.SetFormData("generateTranslations",{...generateTranslations,enabled:e.target.checked},() => {
                                                                this.membershipLib.SetFormData("selectedLang","cz");
                                                            });
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor={"generateTranslations"}>
                                                        Vygenerovat všechny překlady
                                                    </label>
                                                    
                                                </div>

                                                {generateTranslations.enabled && selectedMembershipID && selectedMembershipID != 0 ?
                                                    <img 
                                                        className="cursor-pointer"
                                                        style={{height:"25px",marginLeft:"10px"}} 
                                                        src={settingsIcon} 
                                                        onClick={() => this.setState({showTranslateSettings:!showTranslateSettings})}
                                                    />
                                                :null}
                                                
                                                {!generateTranslations.enabled ?
                                                    <select className="form-control" name="selectedLang" onChange={(e) => this.membershipLib.formLangObjectHandle(e)}>
                                                        {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                                            <option key={index} value={item.languageID}> {item.suffix} </option>
                                                        ))}
                                                    </select>
                                                :null}
                                            </div>
                                            <button type="button" className="close ml-3" onClick={() => this.props.openCloseModal(null)}>                                    
                                                <span aria-hidden="true">x</span>
                                                <span className="sr-only">Close</span>
                                            </button>
                                        </>
                                    :null}
                                    {showTranslateSettings ?
                                        <MembershipTranslateSettings 
                                            generateTranslations = {generateTranslations}
                                            parent = {this}
                                        />
                                    :null}
                                </div>
                                <div className="modal-body">

                                    {err ?
                                        <Error text={err} />
                                    :
                                        <Fragment>
                                            {!loading && !addLoading ?

                                                <>
                                                <div className="form-group input-group">
                                                    <div className="custom-file">
                                                        
                                                        <input
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="customFile"
                                                            name="photo"
                                                            required
                                                            multiple
                                                            onChange={(e) => this.membershipLib.openImage(e)}
                                                        />
                                                        <label className="custom-file-label" htmlFor="customFile">Vyberte fotku</label>
                                                    </div>
                                                </div>

                                                {mainPhoto &&
                                                    <div className="row">  

                                                        <div className="col-12">

                                                            <div className="row">
                                                                <div className="col-12 col-sm-4 mb-4">
                                                                    <img className = "maxwidth-100" src={mainPhoto} />
                                                                </div>
                                                                <div className="col-12 col-sm-8">

                                                                    <div className="row">

                                                                        <div className="col-6">
                                                                            <label className="input-label">Zobrazit v portále a apce</label>
                                                                            <div className="form-group">
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input" type="radio" name="active" id="active1" value="1" checked={(active == 1 ?  true : false)} onChange={(e) => this.membershipLib.formHandle(e)} />
                                                                                    <label className="form-check-label" htmlFor="active1">
                                                                                        Ano
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input" type="radio" name="active" id="active2" value="0" checked={(active == 0 ?  true : false)} onChange={(e) => this.membershipLib.formHandle(e)} />
                                                                                    <label className="form-check-label" htmlFor="active2">
                                                                                        Ne
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label className="input-label">Ukázát název v portále a apce</label>
                                                                            <div className="form-group">
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input" type="radio" name="showName" id="showName1" value="1" checked={(showName == 1 ?  true : false)} onChange={(e) => this.membershipLib.formHandle(e)} />
                                                                                    <label className="form-check-label" htmlFor="showName1">
                                                                                        Ano
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input" type="radio" name="showName" id="showName2" value="0" checked={(showName == 0 ?  true : false)} onChange={(e) => this.membershipLib.formHandle(e)} />
                                                                                    <label className="form-check-label" htmlFor="showName2">
                                                                                        Ne
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label className="input-label">Skrytý (zobrazí se po nákupu)</label>
                                                                            <div className="form-group">
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input" type="radio" name="hidden" id="hidden1" value="1" checked={(hidden == 1 ?  true : false)} onChange={(e) => this.membershipLib.formHandle(e)} />
                                                                                    <label className="form-check-label" htmlFor="hidden1">
                                                                                        Ano
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input" type="radio" name="hidden" id="hidden2" value="0" checked={(hidden == 0 ?  true : false)} onChange={(e) => this.membershipLib.formHandle(e)} />
                                                                                    <label className="form-check-label" htmlFor="hidden2">
                                                                                        Ne
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label className="input-label">Zdarma při registraci v apce</label>
                                                                            <div className="form-group">
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input" type="radio" name="freeAfterReg" id="freeAfterReg1" value="1" checked={(freeAfterReg == 1 ?  true : false)} onChange={(e) => this.membershipLib.formHandle(e)} />
                                                                                    <label className="form-check-label" htmlFor="freeAfterReg1">
                                                                                        Ano
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input" type="radio" name="freeAfterReg" id="freeAfterReg2" value="0" checked={(freeAfterReg == 0 ?  true : false)} onChange={(e) => this.membershipLib.formHandle(e)} />
                                                                                    <label className="form-check-label" htmlFor="freeAfterReg2">
                                                                                        Ne
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6">
                                                                            <label className="input-label">Název ({selectedLang})</label>
                                                                            <div className="form-group">
                                                                                <input className="form-control" type="text" name="name" value={this.membershipLib.getLangValue("name")} onChange={(e) => this.membershipLib.formLangHandle(e)} />
                                                                            </div>
                                                                        </div> 

                                                                        <div className="col-12 col-sm-6">
                                                                            <label className="input-label">Cena ({selectedLang})</label>
                                                                            <div className="form-group">
                                                                                <input className="form-control" type="text" name="value" value={this.membershipLib.getLangValue("value")} onChange={(e) => this.membershipLib.formLangHandle(e)} />
                                                                            </div>
                                                                        </div> 

                                                                        <div className="col-12">
                                                                            <div className="form-group">
                                                                                <label className="input-label">Děkovací stránka</label>
                                                                                <select className="form-control" name="thanksLinkID" value={thanksLinkID} onChange={(e) => this.membershipLib.formHandle(e)}>
                                                                                    <option value={0} key={0}>--Nepoužívat děkovací stránku--</option>
                                                                                    {allLinks.map((item,index) => {
                                                                                        return(
                                                                                            <>
                                                                                                <option value={item.linkID} key={item.linkID}>{item.name}</option>
                                                                                                {item.subLink ?
                                                                                                    (item.subLink.map((subItem,index) => {
                                                                                                        return(
                                                                                                            <option value={subItem.linkID} key={subItem.linkID}>├-- {subItem.name}</option>
                                                                                                        )
                                                                                                    }))
                                                                                                :null}
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="col-12">
                                                            <label className="input-label">Popis ({selectedLang})</label>
                                                            <div className="form-group">
                                                                <TinyMCEBasic 
                                                                    name="description"
                                                                    value={this.membershipLib.getLangValue("description")}
                                                                    OnEditorChange={(content) => this.membershipLib.formLangHandleEditor(content,"description")}
                                                                />
                                                                
                                                            </div>
                                                        </div>

                                                        <div className="col-12">
                                                            <label className="input-label">Popis v upsell bomb ({selectedLang})</label>
                                                            <div className="form-group">
                                                                <TinyMCEBasic 
                                                                    name="upSellBombDescription"
                                                                    value={this.membershipLib.getLangValue("upSellBombDescription")}
                                                                    OnEditorChange={(content) => this.membershipLib.formLangHandleEditor(content,"upSellBombDescription")}
                                                                />
                                                                
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-12">
                                                            <label className="input-label">Text emailu ({selectedLang})</label>
                                                            <div className="form-group">
                                                                <TinyMCEBasic 
                                                                    name="emailText"
                                                                    value={this.membershipLib.getLangValue("emailText")}
                                                                    OnEditorChange={(content) => this.membershipLib.formLangHandleEditor(content,"emailText")}
                                                                />
                                                                
                                                            </div>
                                                        </div>

                                                                                                        
                                                    </div>
                                                }
                                                </>
                                            :

                                                <div>
                                                    {generateTranslations.enabled ?
                                                        <div>
                                                            <p className = "text-center">Překládám...</p>
                                                            <ProgressBar
                                                                progressValue = {this.state.translatedLangsCount}
                                                                totalValue = {allLanguageMutations.length}
                                                            />
                                                        </div>
                                                    :
                                                        <Loading />  
                                                    }
                                                </div>  

                                            }  
                                        </Fragment>
                                    }
                                            
                                </div>
                                <div className="modal-footer"> 
                                    {!loading && !addLoading ? 
                                        <>
                                            <button type="button" className="btn btn-primary flex-fill" onClick={() => this.membershipLib.addMembership(addEditMembership)}>{(selectedMembershipID && selectedMembershipID != 0 ? "Upravit" : "Přidat")}</button>
                                            <button type="button" className="btn btn-danger" onClick={() => this.props.openCloseModal(null)}>Zavřít</button>
                                        </>
                                    :null}
                                </div>
                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(AddEditMembership);