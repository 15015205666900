/*
    Úvodní layout administrace
*/
import React,{ Component, useState } from 'react';
import Header from './Header';
import Notification from '../GlobalComponents/Notification';
import {Switch, Route } from 'react-router-dom';
import withAdminAuthentication from '../Modules/Auth/Components/WithAuthentication';

import AllCategories from '../Modules/Category/Components/AllCategories.js';
import AllAudio from '../Modules/Audio/Components/AllAudios.js';
import AllServices from '../Modules/Service/Components/AllServices.js';
import AllForms from '../Modules/Form/Components/AllForms.js';
import AllArticles from '../Modules/Article/Components/AllArticles.js';
import AllMarketings from '../Modules/Marketing/Components/AllMarketings.js';
import Customer from '../Modules/Customer/Components/Customer';
import ChangePassword from '../Modules/Auth/Components/ChangePassword';
import Link from '../Modules/Content/Components/Link';
import Invoice from '../Modules/Invoice/Components/Invoice';
import InvoiceCreditNote from '../Modules/Invoice/Components/InvoiceCreditNote';
import Newsletter from '../Modules/Newsletter/Components/Newsletter';
import Campaign from '../Modules/Campaign/Components/Campaign';
import AllMemberships from '../Modules/Membership/Components/AllMemberships';
import Order from '../Modules/Order/Components/Order';
import { useQuery } from 'react-apollo';
import { GET_ADMIN_CONTEXT } from '../Modules/Auth/Queries/login';
import NoMatch from '../Routes/NoMatch';
import ExchangeRates from '../Modules/Currency/Components/ExchangeRates.js';


const Layout = () => {

	const {data,loading,error} = useQuery(GET_ADMIN_CONTEXT,{
		fetchPolicy:"network-only"
	});
		
    return (
		<>
			{data ?
				<div className="wholeContent">			       
				
					<div id="layout">	
						<Header adminUser = {data.adminUserContext} />  
						<Switch>

							<Route exact path='/order' render={(props) => <Order adminUserContext={data.adminUserContext} {...props} /> } ></Route>
							<Route exact path='/settings/change-password' component={ChangePassword}></Route>
				
							{data.adminUserContext.adminRoleID == 1 ?
								<>
									<Route exact path='/settings/exchange-rates' component={ExchangeRates}></Route>
									<Route exact path='/customer' component={Customer}></Route>
									<Route exact path='/content/link' component={Link}></Route>
									<Route exact path='/content/article' component={AllArticles}></Route>
									<Route exact path='/content/categories' component={AllCategories}></Route>
									<Route exact path='/content/audio' component={AllAudio}></Route>
									<Route exact path='/order/invoice' component={Invoice}></Route>
									<Route exact path='/order/credit-note' component={InvoiceCreditNote}></Route>
									<Route exact path='/content/services' component={AllServices}></Route>
									<Route exact path='/content/marketing' component={AllMarketings}></Route>
									<Route exact path='/content/forms' component={AllForms}></Route>
									<Route exact path='/content/membership' component={AllMemberships}></Route>
									<Route exact path='/newsletter' component={Newsletter}></Route>
									<Route exact path='/campaign' component={Campaign}></Route>
								</>
							:null}

							<Route component={NoMatch} />

						</Switch>
						<Notification />
					</div>
							
				</div>
			:null}
   		</>
    );
  
}

export default withAdminAuthentication(Layout);
