/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * vložení kategorie
 */
export const ADD_EDIT_MEMBERSHIP = gql`
    mutation AddEditMembership(
        $membershipDurationID:ID,
        $thanksLinkID:ID,
        $length:Int, 
        $autoPaymentsNumber:Int, 
        $showName:Int,
        $active:Int,
        $hidden:Int,
        $freeAfterReg:Int,
        $photo:Upload, 
        $langs:[MembershipLangsInput],
        $generateTranslations:GenerateMembershipTranslationsInput
    ){
        addEditMembership(
            membershipDurationID:$membershipDurationID, 
            thanksLinkID:$thanksLinkID,
            langs:$langs,
            length:$length,
            autoPaymentsNumber:$autoPaymentsNumber,
            showName:$showName,
            active:$active,
            hidden:$hidden,
            freeAfterReg:$freeAfterReg,
            photo:$photo,
            generateTranslations:$generateTranslations
        ){
            membershipDurationID
            name
            lang
        }
    }
`;

/**
 *  Data pro jednu kategorii
 */

export const GET_MEMBERSHIP = gql`
    query MembershipWithLangs($membershipDurationID: ID!){
        membershipWithLangs(membershipDurationID:$membershipDurationID){
            membershipDurationID
            thanksLinkID
            length
            autoPaymentsNumber
            showName
            active
            hidden
            freeAfterReg
            photo
            langs{
                lang
                emailText
                upSellBombDescription
                name
                value
                description
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        allLinks(lang:"cz"){
            linkID
            name
            subLink{
                linkID
                name
                subLink{
                    linkID
                    name
                    subLink{
                        linkID
                        name
                        subLink{
                            linkID
                            name
                            subLink{
                                linkID
                                name
                                subLink{
                                    linkID
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 *  Seznam veškerých kategorií
 */
export const GET_MEMBERSHIPS = gql`
    query AllMemberships($lang: String!){
        allAdminMembershipDurations(lang: $lang){
            membershipDurationID
            name
        }
    }
`;

export const UPDATE_MEMBERSHIPS_PRIORITY = gql`
    mutation updateMembershipPriority($membershipDurationID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateMembershipPriority(membershipDurationID:$membershipDurationID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;

export const GENERATE_MEMBERSHIP_LANG = gql`
    mutation GenerateMembershipDurationLang(
        $membershipDurationID:ID,
        $lang:String,
        $isEdit:Boolean
        $onlyForParams:[String]
    ) {
        GenerateMembershipDurationLang(
            membershipDurationID:$membershipDurationID,
            lang:$lang,
            isEdit:$isEdit
            onlyForParams:$onlyForParams
        )
    }
`;

export const REGENERATE_MEMBERSHIPS_PRICE = gql`
    mutation RegenerateMembershipsPrices{
        RegenerateMembershipsPrices
    }
`;