/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * vložení kategorie
 */
export const ADD_EDIT_AUDIO = gql`
    mutation AddEditAudio(
        $audioID:ID,
        $categoryID:ID,
        $membershipDurationID:ID,
        $audioTypeID:ID,
        $epizodNumber:String, 
        $langs:[AudioLangsInput],
        $generateTranslations:GenerateAudioTranslationsInput
    ){
        addEditAudio(
            audioID:$audioID,
            categoryID:$categoryID,
            membershipDurationID:$membershipDurationID,
            audioTypeID:$audioTypeID,
            epizodNumber:$epizodNumber,
            langs:$langs,
            generateTranslations:$generateTranslations
        ){
            audioID
        }
    }
`;

/**
 *  Úprava priority kategorie, když se přesouvají položky
 */
export const UPDATE_AUDIO_PRIORITY = gql`
    mutation updateAudioPriority($audioID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateAudioPriority(audioID:$audioID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;

export const UPDATE_AUDIO = gql`
    mutation UpdateAudio($audioData:AudioDataInput) {
        UpdateAudio(audioData:$audioData)
    }
`;

/**
 *  Data pro jednu kategorii
 */

export const GET_AUDIO = gql`
    query AudioWithLangs($audioID: ID!){
        audioWithLangs(audioID:$audioID){
            audioID
            hash
            categoryID
            membershipDurationID
            audioTypeID
            epizodNumber
            langs{
                lang
                name
                audioText
                file
                text
                videoUrl
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
        }
        allAdminCategories(lang:"cz"){
            categoryID
            name
        }
        allAdminMembershipDurations(lang:"cz"){
            membershipDurationID
            name
        }
        allAudioTypes{
            audioTypeID
            type
        }
    }
`;

export const GET_AUDIOS = gql`
    query AllAudios($lang: String!){
        allAudios(lang: $lang){
            audioID
            categoryID
            membershipDurationID
            epizodNumber
            hash
            name
            file
            text
            priority
            lang
            category{
                name
                niceName
            }
            membership{
                name
            }
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_AUDIOS = gql`
    mutation deleteAudios($audioIDs:ID!) {
        deleteAudios(audioIDs:$audioIDs)
        audioReindexPriority
    }
`;

export const GENERATE_AUDIO_LANG = gql`
    mutation GenerateAudioLang(
        $audioID:ID,
        $lang:String,
        $isEdit:Boolean
        $onlyForParams:[String]
    ) {
        GenerateAudioLang(
            audioID:$audioID,
            lang:$lang,
            isEdit:$isEdit
            onlyForParams:$onlyForParams
        )
    }
`;

export const GENERATE_LANG_AUDIO_FILE = gql`
    mutation GenerateLangAudioFile(
        $audioID:ID,
        $lang:String
    ) {
        GenerateLangAudioFile(
            audioID:$audioID,
            lang:$lang
        )
    }
`;