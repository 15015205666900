/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

 import Notification from '../../../Library/notification';
 import {GET_CAMPAIGNS, GET_CAMPAIGN} from '../Queries/campaign.js';
 
 class Campaign {
 
     constructor(parent){
         this.parent = parent;
     }
 
     /**
      * Přidání/Editace videa
      * @param {*} addCampaign funkce z apollo, pomocí které se posílají data na server
      */
 
     addCampaign = (addCampaign) => {
 
        const data = this.parent.state.formData;
        let notify = new Notification();   
              
        addCampaign({
            variables:{
                campaignID:data.campaignID,
                name:data.name,
                active:parseInt(data.active),
                isForMainProduct:(data.isForMainProduct ? 1 : 0),
                isForNewsletter:(data.isForNewsletter ? 1 : 0),
                isForIOS:(data.isForIOS ? 1 : 0),
                isForAndroid:(data.isForAndroid ? 1 : 0),
                useEmailsFromNewsletter:(data.useEmailsFromNewsletter ? 1 : 0),
                linkID:data.linkID,
                services:data.selectedServices,
                forms:data.selectedForms,
            }
        });
         
                
     } 
 
 
     /**
      * 
      * @param {*} cache apollo cache, kde jsou uložena data
      * @param {*} response hodnota, která se vrátila po smazání objednávky 
      */
 
     updateAfterDelete(cache, response,lang){
 
         var resp = response.data.deleteCampaigns.split(",");
 
         const { allCampaigns } = cache.readQuery({ query: GET_CAMPAIGNS,variables:{lang}});
         var arr = [...allCampaigns];
         
         resp.forEach((it,ind) => {
             arr.forEach((item,index) => {
                 if(item.campaignID == it){
                     arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                 }
             });
         }) 
         
         cache.writeQuery({ 
             query:GET_CAMPAIGNS,
             variables:{lang},
             data:{
                 allCampaigns: [...arr]
             } 
         });
 
     }
     
     /**
      * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
      * @param {Object} cache apollo cahe v které jsou oložena data
      * @param {Object} response položka, která byla vložena nebo upravována
      * @param {Number} selectedID id položky
      * @param {Object} variables parametry posílané do query
      */
 
     updateList(cache,response,selectedID,lang){
 
         const { allCampaigns } = cache.readQuery({ query: GET_CAMPAIGNS,variables:{lang}});
         
         if(!(selectedID && selectedID != 0)){
 
             cache.writeQuery({ 
                 query:GET_CAMPAIGNS,
                 variables:{lang},
                 data:{
                     allCampaigns: [response.data.addEditCampaign,...allCampaigns]
                 } 
             });
 
         }else{
 
             var arr = [...allCampaigns];
             
             arr.forEach((item,index) => {
                 
                 if(item.campaignID == response.data.addEditCampaign.campaignID){
                     arr[index].campaignID              = response.data.addEditCampaign.campaignID;
                     arr[index].name                    = response.data.addEditCampaign.name;
                     arr[index].active                  = response.data.addEditCampaign.active;
                     arr[index].isForMainProduct        = response.data.addEditCampaign.isForMainProduct;
                     arr[index].isForNewsletter         = response.data.addEditCampaign.isForNewsletter;
                     arr[index].isForIOS                = response.data.addEditCampaign.isForIOS;
                     arr[index].isForAndroid            = response.data.addEditCampaign.isForAndroid;
                     arr[index].useEmailsFromNewsletter = response.data.addEditCampaign.useEmailsFromNewsletter;
                 }
 
             });
             cache.writeQuery({ 
                 query:GET_CAMPAIGNS,
                 variables:{lang},
                 data:{
                     allCampaigns: arr
                 } 
             });
         }
     }
     
     /**
      * při inicializaci přidání kategorie nebo její úpravy vytáhne potřebná data k dopravě
      */
 
     async getData(){
 
         var campaignID = 0;
         if(this.parent.props.selectedCampaignID)campaignID = this.parent.props.selectedCampaignID;
         
         var data = await this.parent.props.client.query({ 
             query: GET_CAMPAIGN,
             errorPolicy:"all",
             variables:{campaignID:campaignID}, 
             fetchPolicy: 'network-only'
         });  
                  
         if(data.errors){
             this.parent.setState({apolloError:data.errors[0].message});
         }
         
         //kdyz upravujeme zaznam
         if(data.data){                 
             if(data.data.campaign){   
                
                var selectedServices = [];
                var selectedForms = [];

                if(data.data.campaign.services){
                    for(let val of data.data.campaign.services){
                        selectedServices.push(val.serviceID);
                    }
                }

                if(data.data.campaign.forms){
                    for(let val of data.data.campaign.forms){
                        selectedForms.push(val.formID);
                    }
                }
                
                this.parent.setState({
                    apolloError:"",
                    selectedCampaignID:campaignID,
                    formData:{...this.parent.state.formData,
                        campaignID:                data.data.campaign.campaignID,
                        name:                      data.data.campaign.name,
                        active:                    data.data.campaign.active,
                        isForMainProduct:          data.data.campaign.isForMainProduct,
                        isForNewsletter:           data.data.campaign.isForNewsletter,
                        isForIOS:                  data.data.campaign.isForIOS,
                        isForAndroid:              data.data.campaign.isForAndroid,
                        useEmailsFromNewsletter:   data.data.campaign.useEmailsFromNewsletter,
                        linkID:                    data.data.campaign.linkID,
                        selectedServices:          selectedServices,
                        selectedForms:             selectedForms

                    },
                    allForms:data.data.allForms,
                    allLinks:data.data.allLinks,
                    allServices:data.data.allServices,
                });
             }else{
                this.parent.setState({ 
                     apolloError:"",
                     allForms:data.data.allForms,
                     allLinks:data.data.allLinks,
                     allServices:data.data.allServices,
                });
             }
         }
 
     }

     checkServices = (e,serviceID) => {

        var checked = e.target.checked;
        var arr = [...this.parent.state.formData.selectedServices];

        if(!checked){
            for(let i in this.parent.state.formData.selectedServices){
                if(this.parent.state.formData.selectedServices[i] == serviceID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [serviceID,...arr];
        }
        
        this.parent.setState({formData:{...this.parent.state.formData,
            selectedServices:arr
        }});
  
    }

    checkForms = (e,formID) => {

        var checked = e.target.checked;
        var arr = [...this.parent.state.formData.selectedForms];

        if(!checked){
            for(let i in this.parent.state.formData.selectedForms){
                if(this.parent.state.formData.selectedForms[i] == formID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [formID,...arr];
        }
        
        this.parent.setState({formData:{...this.parent.state.formData,
            selectedForms:arr
        }});
  
    }
  
     /**
      * funkce ukládá data, která byla vložena do campaignuláře
      * @param {*} e ukazatel na element
      */
     
     formHandle(e){
       
         const t = e.target;
         const v = t.type === 'checkbox' ? t.checked : t.value;
         const n = t.name;
         
         this.parent.setState({formData:{...this.parent.state.formData,[n]: v}});
               
     }
     /**
      * funkce ukládá data po zmene jazykove mutace ve formulari, která byla vložena do formuláře
      * @param {*} e ukazatel na element
      */
     
     formLangObjectHandle(e){
       
         const t = e.target;
         const v = t.value;
         
         for(const lang of this.parent.state.allLanguageMutations){
             if(lang.languageID == v){
                 this.parent.setState({formData:{...this.parent.state.formData,selectedLang: lang.suffix,selectedCurrency:lang.currencyTitle}});
             }
         }
 
               
     }
     
     /**
      * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
      * @param {*} e ukazatel na element
      */
     
     formLangHandle(e){
         
         const t = e.target;
         const v = t.type === 'checkbox' ? t.checked : t.value;
         const n = t.name;
         
         let langs       = this.parent.state.formData.langs;
         for(const lang of langs){
             if(lang.lang == this.parent.state.formData.selectedLang){
                 lang[n] = v;
             }
         }
 
         this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
          
     }
 
     /**
      * funkce ukládá data (jazykove mutace), která byla vložena do formuláře přes tinyMCE editor
      * @param {String} data data tinyMCE editoru
      * @param {String} paramName nazev parametru, kteremu nastavujeme textovou hodnotu
      */
     
     formLangHandleEditor(content,paramName){
         
         let langs       = this.parent.state.formData.langs;
 
         for(const lang of langs){
             if(lang.lang == this.parent.state.formData.selectedLang){
                 lang[paramName] = content;
             }
         }
 
         this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
     }
 
     /**
      * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
      * @param {String} propertyName - název proměnné v objektu lang
      * 
      * @returns property value
      */
     getLangValue = (propertyName) => {
         for(const lang of this.parent.state.formData.langs){
             if(lang.lang == this.parent.state.formData.selectedLang){
                 return lang[propertyName];
             }
         }
         return "";
     }
     
 
     
 
 }
 
 export default Campaign;