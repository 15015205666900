import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import { GET_ORDER,ADD_EDIT_ORDER,GET_ORDERS } from "../Queries/order";
import Modal from 'react-modal';
import Error from "../../../GlobalComponents/Error";
import Loading from "../../../GlobalComponents/Loading";
import Notification from "../../../Library/notification";

const AddEditOrder = ({orderID,SetOrderID,listVariables}) => {

    const [allManufacturedStatus,SetAllManufacturedStatus] = useState([]);
    const [formData, SetFormData] = useState({
        manufacturedStatus:"",
        orderID:0
    })

    const {loading,error} = useQuery(GET_ORDER,{
        variables:{
            orderID
        },
        onCompleted:(data) => {
            SetFormData({...formData,
                manufacturedStatus:data.order.manufacturedStatus,
                orderID:orderID
            });
            SetAllManufacturedStatus(data.allManufacturedStatus)
        }
    })

    const [addEditOrder] = useMutation(ADD_EDIT_ORDER,{
        update : async (cache, response) => {

            const { allOrders } = cache.readQuery({ query: GET_ORDERS, variables:listVariables });

            var arr = [...allOrders];
            
            arr.forEach((item,index) => {
                if(item.customerPaymentID == response.data.addEditOrder.customerPaymentID){

                    arr[index].customerPaymentID             = response.data.addEditOrder.customerPaymentID;
                    arr[index].dateManufacturedStatusChanged = response.data.addEditOrder.dateManufacturedStatusChanged;
                    arr[index].manufacturedStatus            = response.data.addEditOrder.manufacturedStatus;
                    
                }

            });

            cache.writeQuery({ 
                query:GET_ORDERS,
                variables:listVariables,
                data:{
                    allOrders: arr
                } 
            });
            
            let notify = new Notification();

            if(orderID && orderID != 0) notify.setNotification(cache,'Úspěšně uloženo',true,false);
            else notify.setNotification(cache,'Úspěšně přidáno',true,false);
            
        },
        onCompleted:(data) => {
            SetOrderID(0);
        }
    });

    const SaveOrder = () => {

        addEditOrder({
            variables:formData
        })
    }

    const FormHandle = (e) =>
    {
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        SetFormData({...formData,[n]: v});
              
    }

    return(
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => SetOrderID(0)}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">{(orderID && orderID != 0 ? "Úprava objednávky" : "Přidání objednávky")}</h4>
                    <button type="button" className="close" onClick={() => SetOrderID(0)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">

                    <div>
                        {!loading ?
                            <>
                                {error ?
                                    <Error text={error} />
                                :
                                    <div className="row">                            
                                        <div className="col-12 col-sm-3">
                                            <label className="input-label">Status výroby</label>
                                            <div className="form-group">
                                                <select className="form-control" name="manufacturedStatus" value={formData.manufacturedStatus} onChange={(e) => FormHandle(e)}>
                                                    <option value="0"> --- vyberte status výroby --- </option>  
                                                    {allManufacturedStatus && allManufacturedStatus.map((item,index) => (
                                                        <option key={index} value={item.status}> {item.label} </option> 
                                                    ))}  
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                            
                                }
                            </>
                        :
                            <Loading />  
                        }  

                    </div>

                </div>
                <div className="modal-footer"> 
                
                    <button type="button" className="btn btn-primary flex-fill" onClick={() => SaveOrder()}>{(orderID && orderID != 0 ? "Upravit" : "Přidat")}</button>
                    <button type="button" className="btn btn-danger" onClick={() => SetOrderID(0)}>Zavřít</button>
                </div>
            </div>
        </Modal>
    )
}

export default AddEditOrder;