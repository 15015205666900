import React,{ Component,Fragment } from 'react';
import {Mutation, withApollo } from 'react-apollo';
import { createPortal } from 'react-dom'
import move from '../../../Public/Images/move.svg';
import Modal from 'react-modal';
import HeadlineElement from './HeadlineElement';
import ColumnsElement from './ColumnsElement';
import DragColumnsElement from './DragColumnsElement';
import ParagraphElement from './ParagraphElement';
import ImageElement from './ImageElement';
import ButtonElement from './ButtonElement';
import VideoElement from './VideoElement';
import ArticlesElement from './ArticlesElement';
import NewsletterElement from './NewsletterElement'; 
import AddElement from './AddElement';
import HtmlElement from './HtmlElement';
import FaqElement from './FaqElement';
import FormElement from './FormElement';
import ServiceElement from './ServiceElement';
import AlertElement from './AlertElement';
import SliderElement from './SliderElement';
import SeparatorElement from './Separator';
import plusGreen from '../../../Public/Images/plus_green.svg';
import remove from '../../../Public/Images/remove.svg';
import ContentManagerLib from '../Library/contentManager';
import {SECTION} from '../Library/elements';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ProgressBar from '../../../GlobalComponents/ProgressBar';

const INITIAL_STATE = {
    showHelpers:true,
    lang:"cz",
    allLanguages:[],
    windowWidth:0,
    showTranslateModalProgress:false,
    translatedLangsCount:2,
    generateTranslations:{
        enabled:false
    },
    content:{
        sections:[SECTION]    
    }
}

const resize = (parent) => {

    var wWidth = window.innerWidth;  
    parent.setState({windowWidth:wWidth});
    
}

class ContentManager extends Component{

    constructor(props){
        super(props);

        this.state = INITIAL_STATE;
        this.CloseTranslationModalProgress = this.CloseTranslationModalProgress.bind(this);
        
    }

    componentDidMount(){

        const {client,linkID} = this.props;
        this.cm = new ContentManagerLib(this,client,"","",linkID);

        this.cm.getData(linkID);
        this.setState({windowWidth:window.innerWidth});
        window.addEventListener('resize', () => resize(this), false);

    }

    componentWillUnmount(){
        window.removeEventListener('resize',  () => resize(this), false);
    }

    setRef = node => {
        if(node)this.iframeNode = node.node;
    }

    CloseTranslationModalProgress()
    {
        this.setState({showTranslateModalProgress:false});
    }

    render(){

        const {
            content,
            showHelpers,
            lang,
            allLanguages,
            windowWidth,
            generateTranslations,
            showTranslateModalProgress
        } = this.state;
        const {linkID} = this.props;
        
        return(
            <>
                <Modal
                    className="Modal__Bootstrap modal-dialog modal-max"
                    closeTimeoutMS={150}
                    isOpen={true}
                    onRequestClose={() => this.props.openContentManager(0)}
                >

                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Content manager</h4>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" checked={showHelpers} id="defaultCheck1" onChange={() => this.cm.showHideHelpers()} />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                    ovládací prvky
                                </label>
                            </div>
                            <div className="mr-2">
                                <input 
                                    className="form-check-input" 
                                    type="checkbox" 
                                    checked={generateTranslations.enabled} 
                                    id="generateTranslations" 
                                    onChange={() => this.cm.SwitchGenerateTransaltions()} 
                                />
                                <label className="form-check-label" htmlFor="generateTranslations">
                                    Vygenerovat všechny překlady
                                </label>
                            </div>
                            {!generateTranslations.enabled ?
                                <div className="mr-2">
                                    <select style={{padding: '5px', height: '30px'}} value={lang} className="form-control" name="lang" onChange={(e) => this.cm.setLanguage(e)}>
                                        {allLanguages && allLanguages.map((item,index) => (
                                            <option key={index} value={item.suffix}>{item.suffix}</option>
                                        ))}
                                    </select>
                                </div>
                            :null}
                            <button className="btn btn-primary btn-thiner mr-3" onClick={() => this.cm.saveContent(generateTranslations)}>Uložit obsah</button>
                            <button type="button" className="close" onClick={() => this.props.openContentManager(0)}>
                                <span aria-hidden="true">x</span>
                                <span className="sr-only">Close</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                            <Frame ref={this.setRef}>
                                <DragDropContext onDragEnd={(result) => this.cm.onDragSectionEnd(result)}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {content.sections && content.sections.map((item,index) => {

                                                var paddingStyle = {};    

                                                if(windowWidth != 0){
                                                    if(windowWidth >= 0 && windowWidth < 576){
                                                        paddingStyle.paddingTop    = item.ptXs+"px";
                                                        paddingStyle.paddingBottom = item.pbXs+"px";
                                                    }
                                                    if(windowWidth >= 576 && windowWidth < 768){
                                                        paddingStyle.paddingTop    = item.ptSm+"px";
                                                        paddingStyle.paddingBottom = item.pbSm+"px";
                                                    }
                                                    if(windowWidth >= 768 && windowWidth < 992){
                                                        paddingStyle.paddingTop    = item.ptMd+"px";
                                                        paddingStyle.paddingBottom = item.pbMd+"px";
                                                    }
                                                    if(windowWidth >= 992 && windowWidth < 1200){
                                                        paddingStyle.paddingTop    = item.ptLg+"px";
                                                        paddingStyle.paddingBottom = item.pbLg+"px";
                                                    }
                                                    if(windowWidth >= 1200){
                                                        paddingStyle.paddingTop    = item.ptXl+"px";
                                                        paddingStyle.paddingBottom = item.pbXl+"px";
                                                    }
                                                }

                                                if(item.backgroundImage)paddingStyle.backgroundImage = "url('"+item.backgroundImage+"')";
                                                if(item.backgroundImageAlign)paddingStyle.backgroundPosition = item.backgroundImageAlign + " center";
                                                if(item.backgroundImageFill)paddingStyle.backgroundSize = item.backgroundImageFill;


                                                return(

                                                    <Draggable key={index} draggableId={index+1} index={index}>
                                                        {(provided, snapshot) => (
                                                        <div  
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            
                                                        >
                                                    
                                                            <section key={index} className={item.backgroundColorClass + " " + item.className + " " + (content.sections.length == 1 && item.elements.length == 0 ? "cm-min-height" : "")+ " " + (item.fullHeight == 1 ? "full-height" : "")} style={paddingStyle} >
                                                                {content.sections.length == 1 && item.elements.length == 0 &&
                                                                    <div className="cm-start-info">Pro vložení prvního obsahu klikněte v pravo dole na tlačítko "přidat obsah"</div>
                                                                }
                                                                {showHelpers &&
                                                                    <div className="cm-menu">
                                                                        <div className="form-inline">
                                                                            <div className="form-group mr-1">
                                                                                <AddElement lang={lang} isSection={true} lastIndexes = {""+index+""} element={item} cm = {this.cm}  />
                                                                            </div>
                                                                            
                                                                            <div className="form-group mr-1">
                                                                                <div className="item-container" {...provided.dragHandleProps}>
                                                                                    <img className="remove-icon" src={move} alt="Přesunout sekci" title="Přesunout sekci" />
                                                                                </div>
                                                                            </div>
                                                                            {index != 0 &&
                                                                                <div className="form-group">
                                                                                    <div className="item-container" onClick={() => this.cm.removeSection(index)}>
                                                                                        <img className="remove-icon" src={remove} alt="Odstranit sekci" title="Odstranit sekci" />
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className={(item.fullWidth == 1 ? "container-fluid"  : "container")}>

                                                                    <DragDropContext onDragEnd={(result) => this.cm.onDragEnd(result,""+index+"")}>
                                                                        <Droppable droppableId="droppable">
                                                                            {(provided, snapshot) => (
                                                                                <div
                                                                                    {...provided.droppableProps}
                                                                                    ref={provided.innerRef}
                                                                                    
                                                                                >
                                                                        
                                                                                    {item.elements && item.elements.map((elmItem,elmIndex) => {

                                                                                        return(
                                                                                            <Draggable key={elmIndex} draggableId={elmIndex+1} index={elmIndex}>
                                                                                                {(provided, snapshot) => (
                                                                                                <div  
                                                                                                    ref={provided.innerRef}
                                                                                                    {...provided.draggableProps}
                                                                                                    
                                                                                                >

                                                                                                    {elmItem.headline &&
                                                                                                        <HeadlineElement handle = {provided.dragHandleProps}  lang={lang} iframeNode = {this.iframeNode} element = {elmItem.headline} lastIndexes = {index + "-" + elmIndex}  cm = {this.cm} showHelpers = {showHelpers} />
                                                                                                    }
                                                                                                    {elmItem.columns &&
                                                                                                        <ColumnsElement allAdditionalServices = {this.state.allAdditionalServices} allForms={this.state.allForms} allServices={this.state.allServices} handle = {provided.dragHandleProps} lang={lang} fullWidth={item.fullWidth} iframeNode = {this.iframeNode} element = {elmItem.columns} lastIndexes = {index + "-" + elmIndex}  cm = {new ContentManagerLib(this,this.props.client,"columns","column",linkID)} showHelpers = {showHelpers} />
                                                                                                    }
                                                                                                    {elmItem.drag_columns &&
                                                                                                        <DragColumnsElement allAdditionalServices = {this.state.allAdditionalServices} allForms={this.state.allForms} allServices={this.state.allServices} handle = {provided.dragHandleProps} lang={lang} fullWidth={item.fullWidth} iframeNode = {this.iframeNode} element = {elmItem.drag_columns} lastIndexes = {index + "-" + elmIndex}  cm = {new ContentManagerLib(this,this.props.client,"drag_columns","drag_column",linkID)} showHelpers = {showHelpers} />
                                                                                                    }
                                                                                                    {elmItem.paragraph &&
                                                                                                        <ParagraphElement handle = {provided.dragHandleProps} lang={lang} iframeNode = {this.iframeNode} element = {elmItem.paragraph} lastIndexes = {index + "-" + elmIndex}  cm = {this.cm} showHelpers = {showHelpers} />
                                                                                                    }
                                                                                                    {elmItem.image &&
                                                                                                        <ImageElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.image} lastIndexes = {index + "-" + elmIndex}  cm = {this.cm} showHelpers = {showHelpers} />
                                                                                                    }
                                                                                                    {elmItem.button &&
                                                                                                        <ButtonElement allAdditionalServices = {this.state.allAdditionalServices} handle = {provided.dragHandleProps} lang={lang} iframeNode = {this.iframeNode} element = {elmItem.button} lastIndexes = {index + "-" + elmIndex}  cm = {this.cm} showHelpers = {showHelpers} />
                                                                                                    }
                                                                                                    {elmItem.video &&
                                                                                                        <VideoElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.video} lastIndexes = {index + "-" + elmIndex}  cm = {this.cm} showHelpers = {showHelpers} />
                                                                                                    }
                                                                                                    {elmItem.articles &&
                                                                                                        <ArticlesElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.articles} lastIndexes = {index + "-" + elmIndex}  cm = {this.cm} showHelpers = {showHelpers} />
                                                                                                    }
                                                                                                    {elmItem.newsletter &&
                                                                                                        <NewsletterElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.newsletter} lastIndexes = {index + "-" + elmIndex}  cm = {this.cm} showHelpers = {showHelpers} />
                                                                                                    }
                                                                                                    {elmItem.faq &&
                                                                                                        <FaqElement handle = {provided.dragHandleProps} lang={lang} iframeNode = {this.iframeNode} element = {elmItem.faq} lastIndexes = {index + "-" + elmIndex}  cm = {this.cm} showHelpers = {showHelpers} />
                                                                                                    }
                                                                                                    {elmItem.form &&
                                                                                                        <FormElement allForms={this.state.allForms} handle = {provided.dragHandleProps} lang={lang} iframeNode = {this.iframeNode} element = {elmItem.form} lastIndexes = {index + "-" + elmIndex}  cm = {this.cm} showHelpers = {showHelpers} />
                                                                                                    }
                                                                                                    {elmItem.service &&
                                                                                                        <ServiceElement allServices={this.state.allServices} allAdditionalServices = {this.state.allAdditionalServices} handle = {provided.dragHandleProps} lang={lang} iframeNode = {this.iframeNode} element = {elmItem.service} lastIndexes = {index + "-" + elmIndex}  cm = {this.cm} showHelpers = {showHelpers} />
                                                                                                    }
                                                                                                    {elmItem.html &&
                                                                                                        <HtmlElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.html} lastIndexes = {index + "-" + elmIndex}  cm = {this.cm} showHelpers = {showHelpers} />                                       
                                                                                                    }
                                                                                                    {elmItem.alert &&
                                                                                                        <AlertElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.alert} lastIndexes = {index + "-" + elmIndex}  cm = {this.cm} showHelpers = {showHelpers} />
                                                                                                    }
                                                                                                    {elmItem.slider &&
                                                                                                        <SliderElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.slider} lastIndexes = {index + "-" + elmIndex}  cm = {this.cm} showHelpers = {showHelpers} />
                                                                                                    }
                                                                                                    {elmItem.separator &&
                                                                                                        <SeparatorElement handle = {provided.dragHandleProps} lang={lang} element = {elmItem.separator} lastIndexes = {index + "-" + elmIndex}  cm = {this.cm} showHelpers = {showHelpers} />
                                                                                                    }

                                                                                                </div>
                                                                                                )}
                                                                                            </Draggable>
                                                                                        )
                                                                                    })} 
                                                                                    
                                                                                    {provided.placeholder}
                                                                                    
                                                                                </div>
                                                                            )}
                                                                        </Droppable>
                                                                    </DragDropContext>

                                                                </div>

                                                                {showHelpers && <img src={plusGreen} className="cm-add-section" onClick={() => this.cm.addSection(index)} alt="Přidat sekci" title="Přidat sekci" />}
                                                                {showHelpers && <hr className="cm-hr" />}

                                                            </section>
                                                        </div>
                                                        )}
                                                    </Draggable>
                                            )})}

                                            {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                
                            </Frame>
                            

                        </div>
                    </div>

                </Modal>
                <Modal
                    className="Modal__Bootstrap modal-dialog"
                    closeTimeoutMS={150}
                    isOpen={showTranslateModalProgress}
                >
                    <div className="modal-content">
                        <div className="modal-body">

                            <p className = "text-center">Překládám...</p>
                            <ProgressBar
                                progressValue = {this.state.translatedLangsCount}
                                totalValue = {allLanguages.length}
                            />

                        </div>
                    </div>

                </Modal>
            </>
        )

    }
}

class Head extends Component {

    render(){
        return(
            <Fragment>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin={"true"} />
                <link href="https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700;800&display=swap" rel="stylesheet" />
                
                <link rel="stylesheet" href={process.env.PUBLIC_URL + "/Css/bootstrap.css"}></link>
                <link rel="stylesheet" href={process.env.PUBLIC_URL + "/Css/Font/fonts.css"} />
                <link rel="stylesheet" href={process.env.PUBLIC_URL + "/Css/contentManager.css?3"}></link>
                <link rel="stylesheet" href={process.env.PUBLIC_URL + "/Css/web.css?3"}></link>
                
            </Fragment>
        )
    }
}

class Frame extends Component {
    constructor(props) {
      super(props)

      this.state = {
        contentRef:"",
        headRef:""
      }
    }

    componentDidMount() {
        this.node.addEventListener("load", this.handleLoad);
    }

    componentWillUnmout() {
        this.node.removeEventListener("load", this.handleLoad);
    }

    handleLoad = () => {
        this.iframeBody = this.node.contentDocument.body;
        this.iframeHead = this.node.contentDocument.head;
        this.forceUpdate();
    };

  
    render() {
      const { children, ...props } = this.props // eslint-disable-line
      
      return (

        <iframe srcDoc={`<!DOCTYPE html>`} ref={node => (this.node = node)}>
            {this.iframeBody &&
            createPortal(
              children,
              this.iframeBody
            )}
            {this.iframeHead &&
            createPortal(
              <Head />,
              this.iframeHead
            )}
        </iframe>
      )
    }
  }

export default withApollo(ContentManager);