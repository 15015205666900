import React,{ Component,Fragment,useState,useEffect } from 'react';
import ContentEditable from 'react-contenteditable'
import remove from '../../../Public/Images/remove.svg';
import settings from '../../../Public/Images/settings.svg';
import parent from '../../../Public/Images/parent.svg';
import move from '../../../Public/Images/move.svg';


const ButtonElement = (props) => {

    const [additionalService, SetAdditionalService] = useState(null);

    useEffect(() => {

        if(element.additionalServiceID)
            SetLocalAdditionalService(element.additionalServiceID,null);
        
    },[])

    const onPaste = (e,type) => {
        e.preventDefault(); 
        const text = e.clipboardData.getData('text/plain'); 
        if(text != "")props.cm.setElementData(type,props.lastIndexes,"button",text,null,true);
    }

    const SetLocalAdditionalService = (e,lastIndexes) => {

        var selected = false;
        for(let val of props.allAdditionalServices)
        {
            if(val.additionalServiceID == (e.target ? e.target.value : e))
            {
                SetAdditionalService(val);
                selected = true;
                break;
            }
        }

        if(!selected)SetAdditionalService(null);

        if(lastIndexes)
            cm.setElementData(e,lastIndexes,"button");
    } 

    const {element,showHelpers,cm,lastIndexes,lang} = props;
    var parentIndexes = "";
    
    var indexes = lastIndexes.split("-");
    if(indexes.length > 2){
        indexes.pop();
        parentIndexes = indexes.join("-");
    }

    return (
        <div className="cm-headline">

            {showHelpers && element.showMenu &&
                <div className="cm-menu" onClick={(e) => e.stopPropagation()}>
                    <div className="form-inline">
                        
                        <div className="form-group mr-1">
                            <div className="item-container" onClick={() => cm.showSettings("button",lastIndexes)}>
                                <img className="remove-icon" src={settings} alt="Nastavení modulu" title="Nastavení modulu" />
                            </div>
                            {element.showSettings &&
                                <Fragment>
                                    <div className="settings">

                                        <div className="mb-1">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <label className="input-group-text">Doplňková služba:</label>
                                                </div>
                                                <select value={element.additionalServiceID} className="form-control" name="additionalServiceID" onChange={(e) => SetLocalAdditionalService(e,lastIndexes)}>
                                                    <option key={0} value={0}>---vyberte doplňkovou službu ---</option>
                                                    {props.allAdditionalServices && props.allAdditionalServices.map((item,index) => {
                                                        return(<option key={item.additionalServiceID} value={item.additionalServiceID}>{item.name}</option>);
                                                    })}
                                                </select>
                                            </div>
                                        </div>  
                                        <div className="mb-1">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <label className="input-group-text">Dělící čára:</label>
                                                </div>
                                                <select value={element.dividingLine} className="form-control" name="dividingLine" onChange={(e) => cm.setElementData(e,lastIndexes,"button")}>
                                                    <option value="0"> ne </option>
                                                    <option value="1"> ano </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <label className="input-group-text">Ukázat odpočet:</label>
                                                </div>
                                                <select value={element.showCountDown} className="form-control" name="showCountDown" onChange={(e) => cm.setElementData(e,lastIndexes,"button")}>
                                                    <option value="0"> ne </option>
                                                    <option value="1"> ano </option>
                                                </select>
                                            </div>
                                        </div>
                                        {element.showCountDown == 1 ?
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Barva ceny:</label>
                                                    </div>
                                                    <select value={element.priceColorType} className="form-control" name="priceColorType" onChange={(e) => cm.setElementData(e,lastIndexes,"button")}>
                                                        <option value="1"> žluté zlato </option>
                                                        <option value="2"> růžové zlato </option>
                                                    </select>
                                                </div>
                                            </div>
                                        :null}
                                        {element.dividingLine == "1" ?
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Třída dělící čáry:</label>
                                                    </div>
                                                    <input type="text" value={element.dividingLineClass} className="form-control" name="dividingLineClass" onChange={(e) => cm.setElementData(e,lastIndexes,"button")}  />
                                                </div>
                                            </div>
                                        :null}
                                        <div className="mb-1">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <label className="input-group-text">Zarovnání:</label>
                                                </div>
                                                <select value={element.buttonAlign} className="form-control" name="buttonAlign" onChange={(e) => cm.setElementData(e,lastIndexes,"button")}>
                                                    <option value="start"> vlevo </option>
                                                    <option value="center"> centrovaně </option>
                                                    <option value="end"> vpravo </option>
                                                </select>
                                            </div>
                                        </div>
                                        
                                        <div className="mb-1">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <label className="input-group-text">Odkaz:</label>
                                                </div>
                                                <input type="text" value={element.url[lang]} className="form-control" name="url" onChange={(e) => cm.setElementData(e,lastIndexes,"button",null,null,true)}  />
                                            </div>
                                        </div>

                                        <div className="mb-1">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <label className="input-group-text">Barva:</label>
                                                </div>
                                                <select value={element.color} className="form-control" name="color" onChange={(e) => cm.setElementData(e,lastIndexes,"button")}>
                                                    <option value=""> růžové zlato </option>
                                                    <option value="btn-green"> zelená </option>
                                                    <option value="btn-white"> bílá </option>
                                                </select>
                                            </div>
                                        </div>

                                        
                                        <div className="mb-1">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <label className="input-group-text">Velikost:</label>
                                                </div>
                                                <select value={element.size} className="form-control" name="size" onChange={(e) => cm.setElementData(e,lastIndexes,"button")}>
                                                    <option value=""> malá </option>
                                                    <option value="bigger"> střední </option>
                                                    <option value="biggest"> velká </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="mb-1">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <label className="input-group-text">Přeložit pouze toto:</label>
                                                </div>
                                                <select 
                                                    value={element.selectedForGenerateTranslation ? 1 : 0} 
                                                    className="form-control" 
                                                    name="selectedForGenerateTranslation" 
                                                    onChange={(e) => cm.setElementData(e,lastIndexes,"button")}
                                                >
                                                    <option value="0"> Ne </option>
                                                    <option value="1"> Ano </option>
                                                </select>
                                            </div>
                                        </div>
                                        
                                        <div className="mb-1">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <label className="input-group-text">Třída:</label>
                                                </div>
                                                <input type="text" value={element.className} className="form-control" name="className" onChange={(e) => cm.setElementData(e,lastIndexes,"button")}  />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cm-closing-overlay" onClick={() => cm.showSettings("button",lastIndexes)}></div>
                                </Fragment>
                            }
                        </div>
                        {parentIndexes != "" &&
                            <div className="form-group mr-1">
                                <div className="item-container" onClick={(e) => cm.selectElement(e,cm.columnColType,parentIndexes)}>
                                    <img className="remove-icon" src={parent} alt="Nadřazený element" title="Nadřazený element" />
                                </div>
                            </div>
                        }
                        <div className="form-group">
                            <div className="item-container" onClick={() => cm.removeElement(lastIndexes)}>
                                <img className="remove-icon"  src={remove} alt="Odstranit tlačítko" title="Odstranit tlačítko" />
                            </div>
                        </div>
                    </div>  
                </div>
            }

            <div className="cm-headline-content" onClick={(e) => {e.stopPropagation(); !element.showMenu && cm.selectElement(e,"button",lastIndexes)}}>
                <div className={"d-flex justify-content-" + element.buttonAlign}>
                    
                    
                    <div className={"button-container text-" + element.buttonAlign}>

                        {element.showCountDown == 1 ?
                        
                            <div className="cm-content-padding text-center">Zde se bude nacházet odpočet pro slevu kurzů</div>

                        :null}

                        {additionalService ?
                            <div className="text-center">
                                <input type="checkbox" className="mb-3 mr-2" />
                                {additionalService.name}
                            </div>
                        :null}
                        {element.url && element.url != "" ?
                            <ContentEditable onClick={(e) => e.preventDefault()} className={"btn btn-primary " + element.color + " " + element.size + " " + element.className} href={element.url} tagName={"a"} html={element.text[lang]} disabled={false} name="text" onPaste={(e) => onPaste(e,"text")} onChange={(evt) => {cm.setElementData(evt,lastIndexes,"button",null,null,true)}} />
                        :
                            <button className={"btn btn-primary " + element.color + " " + element.size + " " + element.className + (additionalService ? " no-top-margin" : "")}><ContentEditable onClick={(e) => e.preventDefault()} tagName={"span"} html={element.text[lang]} disabled={false} name="text" onPaste={(e) => onPaste(e,"text")} onChange={(evt) => {cm.setElementData(evt,lastIndexes,"button",null,null,true)}} /></button>
                        }

                        {element.dividingLine == "1" ?
                            <div className={"colored-line " + element.dividingLineClass}>
                                <div className="black-line"></div>
                            </div>
                        :null}
                    </div>
                                            
                </div>
            </div>
            {showHelpers && <div className={"cm-border" + (element.showMenu ? " cm-selected" : "")}></div>}
            {showHelpers && 
                <div className="cm-dragable-item" {...props.handle}>
                    <div className="item-container">
                        <img className="move-icon"  src={move} alt="Přesunout element" title="Přesunout element" />
                    </div>
                </div>
            }

        </div>
    );

}

export default ButtonElement;