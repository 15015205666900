/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

import Notification from '../../../Library/notification';
import {GET_MEMBERSHIPS, GET_MEMBERSHIP} from '../Queries/index.js';
import { SERVER_URL,MEMBERSHIP_IMAGE_PATH } from '../../../Config';

class Membership {

    constructor(parent){
        this.parent = parent;
    }

    /**
     * Přidání/Editace videa
     * @param {*} addMembership funkce z apollo, pomocí které se posílají data na server
     */

    addMembership = (addMembership) => {

        let notify = new Notification(); 
        const data = this.parent.state.formData;

        if((data.photo != null && !this.parent.props.selectedMembershipID) || (this.parent.props.selectedMembershipID && this.parent.props.selectedMembershipID != 0))
        { 
             
            let langs = [];
            for(const lang of data.langs){
                langs.push({
                    emailText:              lang.emailText,
                    upSellBombDescription:  lang.upSellBombDescription,
                    lang:                   lang.lang,
                    name:                   lang.name,
                    value:                  parseFloat(lang.value),
                    description:            lang.description
                });
            }

            addMembership({
                variables:{
                    membershipDurationID: data.membershipDurationID,
                    thanksLinkID:         data.thanksLinkID,
                    length:               parseInt(data.length),
                    autoPaymentsNumber:   parseInt(data.autoPaymentsNumber),
                    showName:             parseInt(data.showName),
                    active:               parseInt(data.active),
                    hidden:               parseInt(data.hidden),
                    freeAfterReg:         parseInt(data.freeAfterReg),
                    photo:                data.photo,
                    langs,
                    generateTranslations:data.generateTranslations
                }
            }); 

        }else{
            notify.setNotification(null,'Nevybrali jste fotku.',false,true,this.parent.props.client);
        }      
    } 

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response){

        var resp = response.data.deleteMemberships.split(",");

        const { allAdminMembershipDurations } = cache.readQuery({ query: GET_MEMBERSHIPS ,variables:{lang:'cz'}});
        var arr = [...allAdminMembershipDurations];
        
        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.membershipDurationID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
        
        cache.writeQuery({ 
            query:GET_MEMBERSHIPS,
            variables:{lang:'cz'},
            data:{
                allAdminMembershipDurations: [...arr]
            } 
        });

    }

    SetFormData(type,value,CallBack){

        this.parent.setState({formData:{...this.parent.state.formData,[type]:value}},() => {
            if(CallBack)
                CallBack()
        });
    }
    
    /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {Object} cache apollo cahe v které jsou oložena data
     * @param {Object} response položka, která byla vložena nebo upravována
     * @param {Number} selectedID id položky
     * @param {Object} variables parametry posílané do query
     */

    updateList(cache,response,selectedID,variables){

        const { allAdminMembershipDurations } = cache.readQuery({ query: GET_MEMBERSHIPS,variables:variables });
        
        if(!(selectedID && selectedID != 0)){

            cache.writeQuery({ 
                query:GET_MEMBERSHIPS,
                variables:variables,
                data:{
                    allAdminMembershipDurations: [...allAdminMembershipDurations,response.data.addEditMembership]
                } 
            });

        }else{

            var arr = [...allAdminMembershipDurations];
            
            arr.forEach((item,index) => {
                
                if(item.membershipDurationID == response.data.addEditMembership.membershipDurationID){
                    arr[index].membershipDurationID = response.data.addEditMembership.membershipDurationID;
                    arr[index].name                 = response.data.addEditMembership.name;
                }

            });
            cache.writeQuery({ 
                query:GET_MEMBERSHIPS,
                variables:variables,
                data:{
                    allAdminMembershipDurations: arr
                } 
            });
        }
    }
    
    /**
     * při inicializaci přidání kategorie nebo její úpravy vytáhne potřebná data k dopravě
     */

    async getData(){

        var membershipDurationID = 0;
        if(this.parent.props.selectedMembershipID)membershipDurationID = this.parent.props.selectedMembershipID;
        
        var data = await this.parent.props.client.query({ 
            query: GET_MEMBERSHIP,
            errorPolicy:"all",
            variables:{membershipDurationID:membershipDurationID}, 
            fetchPolicy: 'network-only'
        });      
        
        if(data.errors){
            this.parent.setState({apolloError:data.errors[0].message});
        }
        
        //kdyz upravujeme zaznam
        if(data.data){                  
            if(data.data.membershipWithLangs){    
                
                let langs = this.completeLangsArray(data.data.membershipWithLangs.langs,data.data.allLanguageMutations);     
                
                var mainPhoto = "";
                if(data.data.membershipWithLangs.photo) mainPhoto = SERVER_URL + "/" + MEMBERSHIP_IMAGE_PATH + "/stredni_" + data.data.membershipWithLangs.photo;

                this.parent.setState({
                    apolloError:"",
                    selectedMembershipID:membershipDurationID,
                    allLanguageMutations: data.data.allLanguageMutations,
                    allLinks:data.data.allLinks,
                    mainPhoto,
                    formData:{...this.parent.state.formData,
                        membershipDurationID:     data.data.membershipWithLangs.membershipDurationID,
                        thanksLinkID:             data.data.membershipWithLangs.thanksLinkID,
                        showName:                 data.data.membershipWithLangs.showName,
                        active:                   data.data.membershipWithLangs.active,
                        hidden:                   data.data.membershipWithLangs.hidden,
                        freeAfterReg:             data.data.membershipWithLangs.freeAfterReg,
                        length:                   data.data.membershipWithLangs.length,
                        autoPaymentsNumber:       data.data.membershipWithLangs.autoPaymentsNumber,
                        langs:langs
                    }
                });
            }else{
                this.parent.setState({ apolloError:""});
            }
        }

        //kdyz pridavame zaznam
        if(membershipDurationID == 0){
            let langs = this.completeLangsArray([],data.data.allLanguageMutations);            
            
            this.parent.setState({
                allLanguageMutations: data.data.allLanguageMutations,
                allLinks:data.data.allLinks,
                formData:{...this.parent.state.formData,
                    langs:langs
                }
            });
        }

    }

    completeLangsArray = (langsFromDb,allActiveLanguages) => {
        let langs = langsFromDb;
        for(const l of allActiveLanguages){
            let langSet = false;
            for(const lang of langs){
                if(l.suffix == lang.lang){
                    langSet = true;
                    break;
                }
            }

            if(!langSet){
                langs.push({
                    lang:l.suffix,
                    emailText:"",
                    upSellBombDescription:"",
                    name:"",
                    value:0,
                    description:""

                });
            }
        }
        return langs;
    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formHandle(e){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({formData:{...this.parent.state.formData,[n]: v}});
              
    }
    /**
     * funkce ukládá data po zmene jazykove mutace ve formulari, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangObjectHandle(e){
	  
        const t = e.target;
        const v = t.value;
        
        for(const lang of this.parent.state.allLanguageMutations){
            if(lang.languageID == v){
                this.parent.setState({formData:{...this.parent.state.formData,selectedLang: lang.suffix,selectedCurrency:lang.currencyTitle}});
            }
        }

              
    }
    
    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangHandle(e){
        
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[n] = v;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
         
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře přes tinyMCE editor
     * @param {String} data data tinyMCE editoru
     * @param {String} paramName nazev parametru, kteremu nastavujeme textovou hodnotu
     */
    
    formLangHandleEditor(content,paramName){
        
        let langs       = this.parent.state.formData.langs;

        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[paramName] = content;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
    }
    
    /**
     * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
     * @param {String} propertyName - název proměnné v objektu lang
     * 
     * @returns property value
     */
    getLangValue = (propertyName) => {
        for(const lang of this.parent.state.formData.langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                return lang[propertyName];
            }
        }
        return "";
    }

    openImage(e){
        var photo = e.target.files[0];        
        var reader = new FileReader();
        reader.onload = () => {
            var dataURL = reader.result;
            this.parent.setState({
                mainPhoto:dataURL,
                formData:{...this.parent.state.formData,photo:photo}
            })
        };
        reader.readAsDataURL(photo);
    }
}

export default Membership;