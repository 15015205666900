/*
    Výběr veškerých kategorií
*/
import React,{ Component } from 'react';
import {Query,Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import NoItems from '../../../GlobalComponents/NoItems';
import {UPDATE_LINK_PRIORITY,GET_LINKS} from '../Queries/index.js';
import SubLink from './SubLink';
import AddEditLink from './AddEditLink';
import Helper from '../../../Library/helper';
import ContentManager from '../../ContentManager/Components/ContentManager'

const INITIAL_STATE = {
    showDeleteLinkNotifi : false,
    showDoNotDelete:false,
    showAddLink:false,
    selectedLinkID:0,
    selectedLang:"cz",
    linkID:0,
    parentID:0,
    deleteLink:null,
    contentManagerLinkID:0
}

class AllLinks extends Component{


    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
        this.deleteLink = this.deleteLink.bind(this);
        this.delLink    = this.delLink.bind(this);
        this.showDoNotDel   = this.showDoNotDel.bind(this);
        this.openCloseModal = this.openCloseModal.bind(this);
        this.openContentManager = this.openContentManager.bind(this);
    }

    /**
     * funkce přebírá hodnoty z vnoření komponenty deleteLink
     * @param {*} deleteLink funkce, která posílá data na server
     * @param {*} linkID ID kategorie
     */
    
    deleteLink(deleteLink,linkID,parentID,subLink){
        
        if(subLink){
            this.setState({
                showDoNotDelete:true
            });
        }else{
            this.setState({
                showDeleteLinkNotifi:true,
                deleteLink,
                linkID,
                parentID,
            });
        }
        
        
    }

    delLink(action){

        if(action){
            this.state.deleteLink({
                variables:{
                    linkID: this.state.linkID,
                    parentID: this.state.parentID
                }
            })
        }
        this.setState(INITIAL_STATE);
    }

    showDoNotDel(action){
        this.setState({
            showDoNotDelete:action
        });
    }

    openCloseModal(type,linkID){
        this.setState({
            showAddLink:type,
            selectedLinkID:linkID
        })
    }

    openContentManager(linkID){
        this.setState({
            contentManagerLinkID:linkID
        })
    }

    render(){

        const {selectedLang} = this.state;
        
        return(

            <Query query={GET_LINKS} errorPolicy="all" variables={{lang:selectedLang}}>
                {
                    ({data,loading,error,refetch }) => {
                        if(error){
                            const helper = new Helper(); 
                            return (<Error text={error} />);
                        }
                        if(loading) return (<Loading />);
                        
                        return(
                            <div className="card main">
                            
                                <div className="card-header d-flex align-items-center">
                                    Seznam odkazů
                                    <div className="ml-auto">
                                        <select className="form-control" value={selectedLang} name="selectedLang" onChange={(e) => this.setState({selectedLang:e.target.value})}>
                                            {data.allLanguageMutations && data.allLanguageMutations.map((item,index) => (
                                                <option key={index} value={item.suffix}> {item.suffix} </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button className="btn btn-primary btn-thiner ml-2" onClick={() => this.openCloseModal(true)}>Přidat</button>
                                </div>
                                <div className="card-body">
                                    <div className="data-list">
                                        
                                            <div className="data-list-item header">
                                                <div className="data-list-item-content">
                                                    <div>Název odkazu</div>
                                                    <div className="text-center">Aktivní</div>
                                                    <div className="text-center">V hlavičce</div>
                                                    <div className="text-center">V patičce</div>
                                                    <div className="text-center">Skryté menu</div>
                                                    <div className="text-center">FB contentID</div>
                                                    <div className="text-right">Možnosti</div>
                                                </div>
                                            </div>

                                            <Mutation 
                                                mutation={UPDATE_LINK_PRIORITY} 
                                                update={() => {
                                                    refetch();
                                                }}
                                            >
                                            {
                                                (updateLinkPriority,{error}) => {

                                                    if(error){
                                                        const helper = new Helper(); 
                                                        return (<Error text={helper.getApolloErrorText(error)} />);
                                                    }

                                                    return (
                                                        <SubLink refetchLInks = {refetch} openCloseModal={this.openCloseModal} openContentManager = {this.openContentManager} deleteLink={this.deleteLink} updateLinkPriority={updateLinkPriority} links={data.allLinks} level={0} />
                                                    )
                                            }}
                                            </Mutation>
                                        
                                    </div>
                                    {data.allLinks && data.allLinks.length == 0 &&
                                        <NoItems text={"Momentálně se zde nenachází žádné odkazy."} />
                                    }
                                    {this.state.showDeleteLinkNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat tento odkaz ?'} callback={this.delLink} /> }
                                    {this.state.showDoNotDelete && <ModalNotification yesNo={false} text={'Tento odkaz nelze smazat, protože obsahuje subodkazy. Smažte nejdříve dané subodkazy.'} callback={this.showDoNotDel} /> }
                                    {this.state.showAddLink &&
                                        <AddEditLink openCloseModal={this.openCloseModal} selectedLinkID={this.state.selectedLinkID} />
                                    }
                                    {this.state.contentManagerLinkID != 0 &&
                                        <ContentManager openContentManager={this.openContentManager} linkID={this.state.contentManagerLinkID} />
                                    }
                                </div>
                            </div>
                        );
                    }
                }
            </Query>
        
        );

    }

}


export default AllLinks;