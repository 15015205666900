/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * vložení kategorie
 */
export const ADD_EDIT_SERVICE = gql`
    mutation AddEditService(
        $serviceID:ID,
        $sellAfterMainProduct:ID,
        $photo:Upload,
        $langs:[ServiceLangsInput],
        $selectedServicesToBuy:[ID],
        $generateTranslations:GenerateServiceTranslationsInput
    ){
        addEditService(
            serviceID:$serviceID, 
            sellAfterMainProduct:$sellAfterMainProduct,
            photo:$photo,
            langs:$langs,
            selectedServicesToBuy:$selectedServicesToBuy,
            generateTranslations:$generateTranslations
        ){
            serviceID
            sellAfterMainProduct
            name
            price
            lang
        }
    }
`;

/**
 *  Úprava priority kategorie, když se přesouvají položky
 */
export const UPDATE_SERVICE_PRIORITY = gql`
    mutation updateServicePriority($serviceID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateServicePriority(serviceID:$serviceID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;

/**
 *  Data pro jednu kategorii
 */

export const GET_SERVICE = gql`
    query ServiceWithLangs($serviceID: ID!, $lang:String){
        serviceWithLangs(serviceID:$serviceID){
            serviceID
            sellAfterMainProduct
            photo
            langs{
                lang
                name
                price
                discountPrice
                emailText
                upSellBombDescription
            } 
            servicesToBuy{
                buyServiceID 
            }   
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        allServices(lang: $lang){
            serviceID
            name
        }
    }
`;

/**
 *  Seznam veškerých kategorií
 */
export const GET_SERVICES = gql`
    query AllServices($lang: String!){
        allServices(lang: $lang){
            serviceID
            sellAfterMainProduct
            name
            price
            lang
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_SERVICES = gql`
    mutation deleteServices($serviceIDs:ID!) {
        deleteServices(serviceIDs:$serviceIDs)
    }
`;

/**
 * vyhledání služeb
 */
export const SEACRH_SERVICES = gql`

    query SearchServices($text: String!,$lang:String){
        searchServices(text:$text,lang:$lang){
            name
            serviceID
        }
    }
`;

export const GENERATE_SERVICE_LANG = gql`
    mutation GenerateServiceLang(
        $serviceID:ID,
        $lang:String,
        $isEdit:Boolean
        $onlyForParams:[String]
    ) {
        GenerateServiceLang(
            serviceID:$serviceID,
            lang:$lang,
            isEdit:$isEdit
            onlyForParams:$onlyForParams
        )
    }
`;

export const REGENERATE_SERVICES_PRICE = gql`
    mutation RegenerateServicesPrices{
        RegenerateServicesPrices
    }
`;
